import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { commonFontSize } from "../../../Helpers/ConstantProperties";
import { ShowNumber } from "../../../Helpers/helpers";

export default function BOMItemDetails({ item }) {
    return (
        <>
            <Grid2
                xs={12}
                md={1.5}
                textAlign={{ xs: "left", md: "center" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize}>
                    {item.fgCode}
                </Typography>

            </Grid2>

            <Grid2
                xs={12}
                md={4}
                textAlign={{ xs: "left", md: "center" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize}>
                    {item.name}
                </Typography>

            </Grid2>

            <Grid2
                xs={12}
                md={1.5}
                textAlign={{ xs: "left", md: "center" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize}>
                    {ShowNumber(item.units, 2)}
                </Typography>

            </Grid2>

            <Grid2
                xs={12}
                md={2}
                paddingRight={2}
                textAlign={{ xs: "left", md: "right" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize}>
                    {ShowNumber(item.saleRate, 2, true)}
                </Typography>

            </Grid2>

            <Grid2
                xs={12}
                md={3}
                paddingRight={2}
                textAlign={{ xs: "left", md: "right" }} >

                <Typography fontSize={commonFontSize}>
                    {ShowNumber(item.taxableAmount, 2, true)}
                </Typography>

            </Grid2>
        </>
    )
}