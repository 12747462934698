import React, { useContext } from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GST_RATE, HSN_CODE_PROP, InquiryForm, INSPECTION_REPORT_GROUP_NAME, inspectionPropertyGroup, PROCESS_GROUP_NAME, processPropertyGroup, productProps, propertyList, QC_LIST_GROUP_NAME, qcPropertyGroup, RM_GROUP_NAME, rmPropertyGroup, SchemaTypes } from '../../Helpers/ExtraProperties';
import { INSPECTION_REPORT_LIST, INTERNAL_PROCESS, NUMBERS, PROCESSESS, PRODUCT_STATE, PRODUCT_TYPE_CUSTOM, PRODUCT_TYPE_FINISHED, PRODUCT_TYPE_PROCESS, RMLIST, UPDATE_ON_INQUIRY, UPDATE_ON_PRODUCT } from '../../Helpers/ConstantProperties';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';
import { FirmContext } from '../Contexts/FirmContext';
import { useLocation } from 'react-router-dom';
import { calculateSaleRateFromFinishedGood, deepCopyObject, getUpdatedProp, InpectionHeading, proceessPostDispatch, ProcessHeading, productConfig, QcHeading, RawHeading, updateProductListObjects, ShowNumber, updatePropertyListProduct } from '../../Helpers/helpers';
import ProductTable from './ProductTable';
import QcTable from './QcTable';
import InpectionTable from './InpectionTable';
import { OriginContext } from '../Contexts/OriginContext';

const CreateProduct = () => {
    const { currentFirm } = useContext(FirmContext);
    const { limitFunctionality } = useContext(OriginContext);

    const location = useLocation();
    const locationProps = location.state.extraProp;
    const productType = location.state?.type;
    const productRoute = location.state?.navigateTo;

    var invalidateQueryKey = UPDATE_ON_PRODUCT;

    const queryFunction = async (state, token, khID) => {
        delete state.navigateTo;
        delete state.extraProp;

        if (productType === PRODUCT_TYPE_PROCESS) {
            state.saleRate = !state.saleRate ? 0 : state.saleRate;
            state.GSTRate = !state.GSTRate ? 0 : state.GSTRate;
        }

        state.type = productType

        updateProductListObjects(state, RM_GROUP_NAME);
        updateProductListObjects(state, PROCESS_GROUP_NAME);

        if (productType === PRODUCT_TYPE_CUSTOM) {
            invalidateQueryKey = UPDATE_ON_INQUIRY;

            locationProps.inquiry.products[locationProps.index].saleRate = state.saleRate;
            locationProps.inquiry.products[locationProps.index][RMLIST] = state[RMLIST] || [];
            locationProps.inquiry.products[locationProps.index][PROCESSESS] = state[PROCESSESS] || [];
            locationProps.inquiry.products[locationProps.index][QC_LIST_GROUP_NAME] = state[QC_LIST_GROUP_NAME] || [];
            locationProps.inquiry.products[locationProps.index][INSPECTION_REPORT_LIST] = state[INSPECTION_REPORT_LIST] || [];

            await serviceHelpers.updateInquiry(token, khID, locationProps.inquiry, locationProps.inquiry.id);
            return state.id
        }
        return await serviceHelpers.createProduct(token, khID, state)
    }


    if (currentFirm.gstDropdownList) {
        const obj = productProps.find((element) => element.item.name === GST_RATE);
        obj.item = propertyList.gstDropdown
        obj.item.dropDownList = currentFirm.gstDropdownList;
    }

    var PropsList = productProps;

    var currentData = { unit: NUMBERS, [HSN_CODE_PROP]: currentFirm[HSN_CODE_PROP], GSTRate: currentFirm.defaultGST?.toString() }
    // gstrate has toString because of dropdown thows error if value is number
    if (productType === PRODUCT_TYPE_PROCESS) {
        PropsList = updatePropertyListProduct(deepCopyObject(productProps), PRODUCT_TYPE_PROCESS)
        currentData[PRODUCT_STATE] = INTERNAL_PROCESS
    }

    if (!limitFunctionality) {
        if (productType === PRODUCT_TYPE_FINISHED || productType === PRODUCT_TYPE_CUSTOM) {
            if (productType === PRODUCT_TYPE_CUSTOM) {
                PropsList = [...PropsList, {
                    attributes: {
                        xs: 12,
                        className: 'bg-secondary-subtle word',
                        textAlign: 'center',
                        color: 'warning.main'
                    },
                    warningMsg: <>
                        Requirement is for all the quantities of the finish goods, not just one.<br />
                        Ex: FG Quantity 10, RM will be 12 x 10 = 120
                    </>
                }]
            }
            PropsList = [
                ...PropsList,
                {
                    ...rmPropertyGroup,
                    group: [
                        InquiryForm.RawMaterialDropDown,
                        InquiryForm.Rate,
                        getUpdatedProp(InquiryForm.quantity, { item: { ...propertyList.quantity, type: SchemaTypes.Number } }),
                    ]
                },
                processPropertyGroup,
                inspectionPropertyGroup,
                qcPropertyGroup,
            ];
        }
    }

    const { formTitle, successMessage, addButtonText, displayName } = productConfig[productType];

    const newFormProps = {
        formTitle,
        propertyList: PropsList,
        queryFunction,
        queryKeyValue: invalidateQueryKey,
        navigateTo: productRoute,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText,
        enableBack: true,
        enableVerify: true,
        VerifyAlertComponent: (data) => {
            let message = undefined
            let calculatedSalRate = calculateSaleRateFromFinishedGood(data.data);
            if (data.data.saleRate < calculatedSalRate) message = `SaleRate Should be ${ShowNumber(calculatedSalRate, 2, true)}`
            return <GenericVerifyComponent Body={message} />
        },
        buttonClasses: "",
        currentData: { ...currentData, ...location.state },
        enableClear: true,
        afterDispatch: proceessPostDispatch,
        displayGroupWithProp: true,
        GroupDetailsComponent: (props) => {

            switch (props.groupName) {
                case PROCESS_GROUP_NAME:
                    return <ProductTable
                        {...props}
                        groupFieldName={PROCESS_GROUP_NAME}
                        headingList={ProcessHeading}
                    />
                case RM_GROUP_NAME:
                    return <ProductTable
                        {...props}
                        groupFieldName={RM_GROUP_NAME}
                        headingList={RawHeading}
                    />
                case QC_LIST_GROUP_NAME:
                    return <QcTable
                        {...props}
                        groupFieldName={QC_LIST_GROUP_NAME}
                        headingList={QcHeading}
                    />
                case INSPECTION_REPORT_GROUP_NAME:
                    return <InpectionTable
                        {...props}
                        groupFieldName={INSPECTION_REPORT_GROUP_NAME}
                        headingList={InpectionHeading}
                    />
                default:
                    return <></>
            }

        }
    }

    newFormProps.propertyList[1].item.displayName = displayName;

    return (
        <GenericForm
            {...newFormProps}
        />
    )
}
export default CreateProduct
