import GenericDivider from "../../GenericComponents/Layout/GenericDivide";
import BankComponent from "./BankComponent";
import BOMItems from "./BOMItems";
import DetailsComponent from "./DetailsComponent";
import DocumentHeading from "./DocumentHeading";
import FormatLogo from "./FormatLogo";
import FromComponent from "./FromComponent";
import SignatureComp from "./SignatureComp";
import TermsComponent from "./TermsComponent";
import ToComponent from "./ToComponent";
import TotalComponent from "./TotalComponent";
import TotalInWords from "./TotalInWords";

export const defaultFormatLayout = [
    {
        lable: "Logo",
        layout: {
            xs: 3,
        },
    },
    {
        lable: "Empty",
        layout: {
            display: { xs: "none", md: "block" },
            md: 4
        },
    },
    {
        lable: "Heading",
        layout: {
            xs: 8,
            md: 5
        },
    },
    {
        lable: "Divider",
        layout: {
            paddingY: 2,
            display: { xs: "none", md: "block" },
            md: 12
        },
    },
    {
        lable: "From",
        layout: {
            xs: 12,
            md: 4
        },
    },
    {
        lable: "To",
        layout: {
            xs: 12,
            md: 4
        },
    },
    {
        lable: "Details",
        layout: {
            xs: 12,
            md: 4
        },
    },
    {
        lable: "Items",
        layout: {
            paddingY: 2,
            xs: 12
        },
    },
    {
        lable: "Tax",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 8
        },
    },
    {
        lable: "Total",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 4
        },
    },
    {
        lable: "Words",
        layout: {
            paddingY: 2,
            xs: 12
        },
    },
    {
        lable: "Bank",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 4
        },
    },
    {
        lable: "Terms",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 4.5
        },
    },
    {
        lable: "Sign",
        layout: {
            paddingY: 2,
            display: { xs: "none", md: "block" },
            md: 3.5
        },
    }
]

export const fomatComponents = (lable) => {
    let componentToReturn = null;

    switch (lable) {
        case "Logo":
            componentToReturn = <FormatLogo />
            break;

        case "Empty":
            componentToReturn = <></>
            break;

        case "Heading":
            componentToReturn = <DocumentHeading />
            break;

        case "Divider":
            componentToReturn = <GenericDivider />
            break;

        case "From":
            componentToReturn = <FromComponent />
            break;

        case "To":
            componentToReturn = <ToComponent />
            break;

        case "Details":
            componentToReturn = <DetailsComponent />
            break;

        case "Total":
            componentToReturn = <TotalComponent />
            break;

        case "Words":
            componentToReturn = <TotalInWords />
            break;

        case "Bank":
            componentToReturn = <BankComponent />
            break;

        case "Terms":
            componentToReturn = <TermsComponent />
            break;

        case "Sign":
            componentToReturn = <SignatureComp />
            break;

        case "BOMItems":
            componentToReturn = <BOMItems />
            break;

        default: break;
    }

    return componentToReturn;
}