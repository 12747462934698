import { useNavigate } from "react-router-dom";
import GenericForm from "../GenericComponents/FormComponent/GenericForm";
import { STOCK_STMT, UPDATE_ON_PRODUCT } from "../../Helpers/ConstantProperties";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import InquiryProductDetails from "../Inquiry/InquiryProductDetails";
import RequirementRawMaterialContent from "../Requirement/RequirementRawMaterialContent";
import { InwordFGPropList, InwordRMPropList, PRODUCTSGROUP, requirementRawMaterialHeading, RM_GROUP_NAME } from "../../Helpers/ExtraProperties";
import { inwordRMPostDispatch } from "../../Helpers/helpers";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { useContext } from "react";
import { OriginContext } from "../Contexts/OriginContext";

export default function InwordRMStock() {
    const navigate = useNavigate();
    const handleReditect = () => {
        navigate(STOCK_STMT)
    }

    const { limitFunctionality } = useContext(OriginContext);
    const propName = limitFunctionality ? PRODUCTSGROUP : RM_GROUP_NAME;
    const propList = limitFunctionality ? InwordFGPropList : InwordRMPropList

    const successMessage = "Stock Inword done"

    const inwordStockFn = async (state, token, khID) => {
        return await serviceHelpers.inwordProductToStore(token, khID, state, state[propName])
    };

    const FormProperties = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Add",
        navigateTo: STOCK_STMT,
        queryKeyValue: UPDATE_ON_PRODUCT,
        formTitle: "Inword Stock",
        propertyList: propList,
        queryFunction: inwordStockFn,
        buttonClasses: "",
        currentData: {},
        handleCancel: handleReditect,
        afterDispatch: inwordRMPostDispatch,
        GroupDetailsComponent: (props) => <InquiryProductDetails
            ResponsiveContentComponent={RequirementRawMaterialContent}
            {...props}
            headingList={requirementRawMaterialHeading}
            groupFieldName={propName} />,
        enableClear: true
    }

    return (
        <GenericForm {...FormProperties} />
    )
}