import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import { getTermsAndConditions } from "../../../Helpers/helpers";
import Grid2 from "@mui/material/Unstable_Grid2";
import HeadingCard from "../QuotationDetails/HeadingCard";
import { Typography } from "@mui/material";
import { commonFontSize } from "../../../Helpers/ConstantProperties";
import { FirmContext } from "../../Contexts/FirmContext";

export default function TermsComponent() {
    const { context } = useContext(DocumentContext);
    const { currentFirm } = useContext(FirmContext);

    const item = context.inquiry;
    const termsAndConditions = getTermsAndConditions(item);

    termsAndConditions.push(
        { text: "Subject to " + currentFirm.city + " jurisdiction" },
        { text: "Section 43B clause (h) is applicable" }
    )

    return (
        <Grid2
            container
            alignContent={"start"}
            height={"100%"}
            border={{ xs: "none", md: "1px solid lightgrey" }}>

            <HeadingCard title={"Terms and Conditions"} />

            <Grid2 xs={12} md={8} alignContent={"space-around"}>

                {termsAndConditions.map(({ label, text }, index) => (
                    <Grid2 key={index} container>
                        <Grid2 xs={12}>
                            <Typography fontSize={commonFontSize} >
                                <span>{label && <strong>{label}: </strong>}</span> {text}
                            </Typography>
                        </Grid2>
                    </Grid2>
                ))}
            </Grid2>

        </Grid2>
    )
}