import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { REQUIREMENT, SET_QUOTE_TERMS } from '../../../Helpers/ConstantProperties';

const ApplyTermsAndCondition = ({ item }) => {
    const navigate = useNavigate();
    const currentPath = REQUIREMENT;

    const handleApplyTermsAndCondition = () => {
        navigate(SET_QUOTE_TERMS, {
            state: { inquiry: { ...item }, navigateTo: currentPath }
        })

    }

    return (
        <MenuItem onClick={handleApplyTermsAndCondition}>Quotation Terms</MenuItem>
    );
};

export default ApplyTermsAndCondition;