import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ShowNumber } from "../../../Helpers/helpers";
import { Typography } from "@mui/material";
import { commonFontSize, largeFontSize } from "../../../Helpers/ConstantProperties";

export default function TotalComponent() {
    const { context } = useContext(DocumentContext);
    const cData = context.cData;

    return (
        <Grid2
            height={"100%"}
            alignContent={"end"}
            container
            rowGap={0.5}
            paddingLeft={{ xs: 0, md: 3 }}>

            <Grid2 xs={12}>
                <Typography component={'span'} fontSize={commonFontSize}>
                    <Grid2 container>
                        <Grid2 xs={8} textAlign={"right"}>
                            Total Taxable:
                        </Grid2>
                        <Grid2 xs={4} textAlign={"right"} paddingRight={1}>
                            {ShowNumber(cData.totalTaxableAmount, 2, true)}
                        </Grid2>
                    </Grid2>
                </Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Typography component={'span'} fontSize={commonFontSize}>
                    <Grid2 container>
                        <Grid2 xs={8} textAlign={"right"}>
                            Total Tax:
                        </Grid2>
                        <Grid2 xs={4} textAlign={"right"} paddingRight={1}>
                            {ShowNumber(cData.totalTax, 2, true)}
                        </Grid2>
                    </Grid2>
                </Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Typography component={'span'} fontSize={commonFontSize}>
                    <Grid2 container>
                        <Grid2 xs={8} textAlign={"right"}>
                            Round-Off:
                        </Grid2>
                        <Grid2 xs={4} textAlign={"right"} paddingRight={1}>
                            {ShowNumber(cData.roundOff, 2, true)}
                        </Grid2>
                    </Grid2>
                </Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Typography component={'span'} fontSize={largeFontSize}>
                    <Grid2 container className="bg-primary-subtle py-2 rounded">
                        <Grid2 xs={8} textAlign={"right"}>
                            Grand Total:
                        </Grid2>
                        <Grid2 xs={4} textAlign={"right"} paddingRight={1}>
                            {ShowNumber(cData.roundedTotal, 2, true)}
                        </Grid2>
                    </Grid2>
                </Typography>
            </Grid2>
        </Grid2>
    )
}