import { useContext } from "react";
import { FirmContext } from "../../Contexts/FirmContext";
import { createUPILink, getBankDetails } from "../../../Helpers/helpers";
import Grid2 from "@mui/material/Unstable_Grid2";
import { QRCodeSVG } from 'qrcode.react';
import HeadingCard from "../QuotationDetails/HeadingCard";
import { Typography } from "@mui/material";
import { commonFontSize } from "../../../Helpers/ConstantProperties";

export default function BankComponent() {
    const { currentFirm } = useContext(FirmContext);
    const bankDetails = getBankDetails(currentFirm);
    const formatedUpiLink = createUPILink(currentFirm.upiID);

    return (
        <Grid2 container border={{ xs: "none", md: "1px solid lightgrey" }}>
            <HeadingCard title={"Bank Details"} />

            <Grid2 xs={12} md={3} className="p-2">
                <QRCodeSVG value={formatedUpiLink} className="w-100 h-auto" />
            </Grid2>


            <Grid2 xs={12} md={8} alignContent={"space-around"}>

                {bankDetails.map(({ label, text }, index) => (
                    <Grid2 key={index} container>
                        <>
                            <Grid2 xs={4}>
                                <Typography fontSize={commonFontSize} >
                                    <span>{label && <strong>{label}: </strong>}</span>
                                </Typography>
                            </Grid2>

                            <Grid2 xs={8}>
                                <Typography fontSize={commonFontSize}>
                                    {text}
                                </Typography>
                            </Grid2>
                        </>
                    </Grid2>
                ))}

            </Grid2>

        </Grid2>
    )

}