import { useContext } from "react";
import { INQUIRY_STORE_ACCOUNT_ID, INWORD_RM_STOCK, PRODUCT_TYPE_PROCESS, PRODUCTS, UPDATE_ON_PRODUCT } from "../../Helpers/ConstantProperties";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import DataView from "../GenericComponents/DataView/DataView";
import StockCard from "./StockCard";
import { PRODUCT_TAG } from "../../Helpers/ExtraProperties";

export default function StockBalance() {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const queryKey = [
        currentUserObject.uid,
        khID,
        UPDATE_ON_PRODUCT,
        PRODUCTS
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getResourceBalance(
            token,
            khID,
            {
                accountID: INQUIRY_STORE_ACCOUNT_ID,
                date: Date.now(),
                tag: PRODUCT_TAG,
            },
            pageParam);
    }

    const getSearchableValue = (current) => {
        return (
            current.resource.name + " "
        )
    }

    const filterData = (data) => {
        return data.filter((balance) => {
            if (balance.units === 0) return false;
            return balance.resource.type !== PRODUCT_TYPE_PROCESS
        })
    }

    return (
        <DataView
            routeDetails={{ heading: "Stock Management" }}
            limitSupported={true}
            filterData={filterData}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: StockCard }}
            searchingPlaceholder={"Search By Name"}
            buttonDetails={{ text: "Inword Stock", navigateTo: INWORD_RM_STOCK }}
        />
    );
}