import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { commonFontSize, commonFontWeight, PRIMARY_COLOR } from "../../../Helpers/ConstantProperties";
import { numberToWords } from "../../../Helpers/helpers";

export default function TotalInWords() {
    const { context } = useContext(DocumentContext);
    const cData = context.cData;

    return (
        <Grid2 container>
            <Grid2 xs>

                <Typography
                    component={"span"}
                    textAlign={'left'}
                    fontSize={commonFontSize}
                    fontWeight={commonFontWeight}>

                    {"Total Amount in words: "}

                </Typography>

                <Typography
                    component={"span"}
                    textAlign={'left'}
                    fontSize={commonFontSize}
                    color={PRIMARY_COLOR}>
                    {` ${numberToWords(cData.roundedTotal)}`}
                </Typography>
            </Grid2>

        </Grid2>
    )
}