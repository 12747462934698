import { deepCopyObject } from "../../../Helpers/helpers";
import { defaultFormatLayout, fomatComponents } from "../CommonComp/commonFormatData";
import ItemsFormat from "./ItemsFormat";
import TaxTable from "./TaxTable";

const defaultGetComponent = (lable) => {
    let componentToReturn = null;

    switch (lable) {
        case "Items":
            componentToReturn = <ItemsFormat />
            break;

        case "Tax":
            componentToReturn = <TaxTable />
            break;

        default:
            componentToReturn = fomatComponents(lable)
            break

    }

    return componentToReturn;
}

// For BOM we need to change the item table
const bomLayout = deepCopyObject(defaultFormatLayout);
const index = bomLayout.findIndex((entry) => entry.lable === "Items");
if (index !== -1) bomLayout[index].lable = "BOMItems"

export const defaultFormat = {
    mainClass: "p-2",
    quoteLayout: defaultFormatLayout,
    invoiceLayout: defaultFormatLayout,
    bomLayout: bomLayout,
    getComponent: defaultGetComponent,
}