import { Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { commonFontSize } from "../../../Helpers/ConstantProperties"
import { ShowNumber } from "../../../Helpers/helpers"

export default function TaxCell({ tax, isIGST }) {
    if (isIGST) {
        return (
            <Grid2
                xs={12}
                md={3}
                display={{ xs: "none", md: "block" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize} textAlign={"right"} paddingRight={1}>
                    {ShowNumber(tax, 2, true)}
                </Typography>

            </Grid2>
        )
    }

    return (
        <>
            <Grid2
                xs={12}
                md={1.5}
                display={{ xs: "none", md: "block" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize} textAlign={"right"} paddingRight={1}>
                    {ShowNumber((tax / 2), 2, true)}
                </Typography>

            </Grid2>

            <Grid2
                xs={12}
                md={1.5}
                display={{ xs: "none", md: "block" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <Typography fontSize={commonFontSize} textAlign={"right"} paddingRight={1}>
                    {ShowNumber((tax / 2), 2, true)}
                </Typography>

            </Grid2>
        </>
    )
}