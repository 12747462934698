import React, { useContext, useState } from 'react';
import { Grid, IconButton, Menu, Button, Divider } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CreateCustomer from './DashboardMenuOptions/CreateCustomer';
import EditInquiryAction from './DashboardMenuOptions/EditInquiryAction';
import GenerateQuotation from './DashboardMenuOptions/GenerateQuotation';
import GenericDialog from '../GenericComponents/Dialog/GenericDialog';
import RejectInquiry from './DashboardMenuOptions/RejectInquiry';
import AddDesign from '../Inquiry/AddDesign';
import AddRequirement from './DashboardMenuOptions/AddRequirement';
import {
    CONVERTED,
    DEFAULT_TEXT_COLOR,
    NEW_PRODUCTION,
    regularFontSize,
    STOCK_MANAGEMENT,
    DELIVERY_AND_INVOICE_MANAGEMENT,
    CREATE_INSPECTIONS,
    DISPLAY_INSPECTIONS,
    NEW_QUALITY_CHECK
} from '../../Helpers/ConstantProperties';
import GenerateBOM from './DashboardMenuOptions/GenerateBOM';
import ViewOrder from '../Order/OrderActions/ViewOrder';
import EditOrder from '../Order/OrderActions/EditOrder';
import { CATEGORIES, disableAddReq, disableBOM, disableQuote, disableInvoice, comparePath, disableForRevise } from '../../Helpers/helpers';
import { UserActionContext } from './SummaryPage';
import GenerateInvoice from './DashboardMenuOptions/GenerateInvoice';
import ApplyTermsAndCondition from './DashboardMenuOptions/ApplyTermsAndCondition';
import StockMenu from '../Stock/StockMenu';
import AddDiscount from './DashboardMenuOptions/AddDiscount';
import GenerateOutwordChallan from './DashboardMenuOptions/GenerateOutwordChallan';
import AddInspection from './DashboardMenuOptions/AddInspection';
import { OriginContext } from '../Contexts/OriginContext';
import ViewInspection from './DashboardMenuOptions/ViewInspection';
import ReviseQuotation from './DashboardMenuOptions/ReviseQuotation';
import AssignGoodsMenu from '../AssignFinishGood/AssignGoodsMenu';

const defaultButton = {
    color: DEFAULT_TEXT_COLOR,
    justifyContent: 'center',
    padding: 0,
    textTransform: 'none',
    cursor: 'none',
    fontSize: regularFontSize,
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'grey.300',
    },
};

const CustomButton = ({ children, sxProps, showDivider, ...props }) => (
    <>
        <Button
            fullWidth
            sx={{
                ...defaultButton,
                ...sxProps,
            }}
            {...props}
        >
            {children}
        </Button>
        {showDivider && <Divider sx={{ margin: 0, backgroundColor: 'black' }} />}
    </>
);

const DashboardActions = ({ item }) => {
    const { limitFunctionality } = useContext(OriginContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [contents, setContents] = useState(<></>);
    const [title, setTitle] = useState("");
    const { showAssigned, routeDetails } = useContext(UserActionContext);
    const disableForAssigned = showAssigned || disableAddReq(item)

    const handleOpenDialog = (contents, title) => {
        setContents(contents)
        setTitle(title);
        setDialogOpen(true);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const buttonConfigs = [
        {
            sxProps: { backgroundColor: 'grey.300' },
            component: 'More Actions'
        },
        {
            disable: showAssigned
                || CATEGORIES[item.status]?.disableEdit
                || CATEGORIES[item.status]?.showInOrder,
            component: <EditInquiryAction item={item} handleClose={handleClose} />
        },
        {
            disable: !!item.customerId,
            component: <CreateCustomer item={item} />
        },
        {
            disable: limitFunctionality || disableForAssigned,
            component: <AddRequirement item={item} />
        },
        {
            disable: disableForAssigned,
            component: <ApplyTermsAndCondition item={item} />
        },
        {
            disable: showAssigned || disableQuote(item),
            component: <GenerateQuotation item={item} />
        },
        {
            disable: showAssigned || disableQuote(item),
            component: <AddDiscount item={item} handleMenuItemClick={handleOpenDialog} handleClose={() => setDialogOpen(false)} />
        },
        {
            disable: showAssigned || disableBOM(item),
            component: <GenerateBOM item={item} />
        },
        {
            disable: (showAssigned || disableInvoice(item)) && !comparePath(routeDetails?.navigateTo, DELIVERY_AND_INVOICE_MANAGEMENT),
            component: <GenerateInvoice item={item} />
        },       
        {
            disable: showAssigned || !comparePath(routeDetails?.navigateTo, DELIVERY_AND_INVOICE_MANAGEMENT),
            component: <GenerateOutwordChallan item={item} />
        },
        {
            disable: showAssigned || item.status !== CONVERTED,
            component: <AddDesign item={item} handleMenuItemClick={handleOpenDialog} handleClose={() => setDialogOpen(false)} />
        },
        {
            disable: !CATEGORIES[item.status]?.showInOrder,
            component: <ViewOrder item={item} />,
        },
        {
            disable: disableForRevise(item),
            component: <ReviseQuotation item={item} />,
        },
        {
            disable: limitFunctionality || !comparePath(routeDetails?.navigateTo, NEW_PRODUCTION), // This condition used for to show this option for the production menu only.
            component: <AddInspection item={item} handleMenuItemClick={handleOpenDialog} handleClose={() => setDialogOpen(false)} source={CREATE_INSPECTIONS} />,
        },
        {
            disable: showAssigned && !CATEGORIES[item.status]?.showInOrder,
            component: <EditOrder item={item} />,
        },
        {
            disable: item.status !== CONVERTED,
            component: <AssignGoodsMenu item={item} />,
        },
        {
            disable: limitFunctionality || !comparePath(routeDetails?.navigateTo, NEW_QUALITY_CHECK),
            component: <ViewInspection item={item} handleMenuItemClick={handleOpenDialog} handleClose={() => setDialogOpen(false)} source={DISPLAY_INSPECTIONS} />,
        },
        {
            disable: !comparePath(routeDetails?.navigateTo, STOCK_MANAGEMENT),
            component: <StockMenu item={item} handleMenuItemClick={handleOpenDialog} handleClose={() => setDialogOpen(false)} />,
        },
        {
            component: <RejectInquiry item={item} handleMenuItemClick={handleOpenDialog} handleClose={() => setDialogOpen(false)} />
        },
    ];

    const filteredConfig = buttonConfigs.filter((config) => !config.disable);

    const moreActions = filteredConfig.map((button, index) => {
        return (
            <CustomButton
                key={index}
                sxProps={button.sxProps || defaultButton}
                showDivider={index < buttonConfigs.length - 1}
                disabled={button.extraProps?.disableMenu}
            >
                {button.component}
            </CustomButton>
        )
    });

    return (
        <Grid item>
            <IconButton
                aria-label="more"
                color="info"
                onClick={handleClick}
            >
                <MoreHorizRoundedIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    sx: { padding: 0 }
                }}
            >
                {moreActions}
            </Menu>

            <GenericDialog
                title={title}
                open={dialogOpen}
                setOpen={setDialogOpen}
                content={contents}>
            </GenericDialog>
        </Grid>
    );
};

export default DashboardActions;