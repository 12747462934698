import { QC_STORE_ACCOUNT_ID, QUALITY_CHECK } from '../../Helpers/ConstantProperties';
import { StoreBalanceStock } from '../Stock/StoreBalanceStock'

const QualityStockBalance = () => {
  return (
      <StoreBalanceStock
        Dashboardtitle="Quality Check"
        queryKeyValue={QUALITY_CHECK}
        BalanceAcc={QC_STORE_ACCOUNT_ID}
      />
  );
}

export default QualityStockBalance