import { useContext } from "react";
import { FirmContext } from "../../Contexts/FirmContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import HeadingCard from "../QuotationDetails/HeadingCard";
import { Typography } from "@mui/material";
import { commonFontSize } from "../../../Helpers/ConstantProperties";

export default function SignatureComp() {
    const { currentFirm } = useContext(FirmContext);

    return (
        <Grid2
            container
            border={'1px solid lightgrey'}
            height={"100%"}>

            <Grid2 xs={12}>
                <HeadingCard title="Signature" />
            </Grid2>

            <Grid2
                container
                direction="column"
                justifyContent="end"
                sx={{ flexGrow: 1, textAlign: 'center' }}>

                <Typography fontSize={commonFontSize}>
                    {"For " + currentFirm.name}
                </Typography>

            </Grid2>
        </Grid2>
    );
}