import { useContext } from "react";
import { DocumentContext, PageContext } from "../../GenericComponents/Layout/DocumentFormat";
import { NewquotationTableValues } from "../../../Helpers/helpers";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import TableHeader from "../CommonComp/TableHeader";
import ItemDetails from "./ItemDetails";
import NewTotalRow from "./NewTotalRow";
import { DocumentFormatContext } from "../../Contexts/DocumentFormatContext";

export default function NewItemFormat() {
    const { context } = useContext(DocumentContext);
    const { page } = useContext(PageContext);
    const { selectedFormat } = useContext(DocumentFormatContext);

    const cData = context.cData;
    const pageSize = selectedFormat.pageSize ? selectedFormat.pageSize : cData?.items.length;

    const cursur = pageSize * page;
    const toCursur = pageSize * (page + 1);

    const itemsToShow = cData?.items.slice(cursur, toCursur);

    const isLast = toCursur >= cData?.items.length;

    if (itemsToShow.length === 0) return <></>

    return (
        <Box
            className='rounded'
            border={{ xs: 'none', md: '1px solid lightgray' }}
        >
            <Grid2 container>
                <TableHeader propList={NewquotationTableValues} />

                {
                    itemsToShow.map((item) => (
                        <Grid2
                            key={item.id}
                            xs={12}
                            border={{ xs: 'none', md: '1px solid lightgray' }}>

                            <Grid2 container paddingY={0.5}>
                                <ItemDetails item={item} />
                            </Grid2>

                        </Grid2>
                    ))
                }

                <Grid2 xs={12} className="bg-light">
                    <NewTotalRow isLast={isLast} />
                </Grid2>
            </Grid2>
        </Box>
    )
}