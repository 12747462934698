import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { commonFontSize } from "../../../Helpers/ConstantProperties";
import { ShowNumber } from "../../../Helpers/helpers";
import TaxCell from "./HSNTaxCell";

export default function HSNSummary({ hsnData, isIGST }) {
    return (
        <Grid2 container>
            <Grid2 xs={12} md={2} borderRight={{ xs: 'none', md: '1px solid lightgray' }}>
                <Typography fontSize={commonFontSize}>
                    {hsnData.hsncode}
                </Typography>
            </Grid2>

            <Grid2 xs={12} md={1} borderRight={{ xs: 'none', md: '1px solid lightgray' }}>
                <Typography fontSize={commonFontSize}>
                    {hsnData.GSTRate} %
                </Typography>
            </Grid2>

            <Grid2 xs={12} md={1} borderRight={{ xs: 'none', md: '1px solid lightgray' }}>
                <Typography fontSize={commonFontSize}>
                    {hsnData.quantity}
                </Typography>
            </Grid2>

            <Grid2 xs={12} md={2.5} borderRight={{ xs: 'none', md: '1px solid lightgray' }}>
                <Typography fontSize={commonFontSize} textAlign={"right"} paddingRight={1}>
                    {ShowNumber(hsnData.taxable, 2, true)}
                </Typography>
            </Grid2>

            <TaxCell tax={hsnData.tax} isIGST={isIGST} />

            <Grid2 xs={12} md={2.5}>
                <Typography fontSize={commonFontSize} textAlign={"right"} paddingRight={1}>
                    {ShowNumber(hsnData.tax, 2, true)}
                </Typography>
            </Grid2>

        </Grid2>
    )
}