import { DOC_TYPE_INVOICE } from '../../Helpers/ConstantProperties'
import QuotationPage from '../Quotation/QuotationPage'

const InvoicePage = ({ item, showWarning}) => {
  return (
    <QuotationPage
      documentTitle="Tax Invoice"
      firmTitle="Bill From"
      clientTitle="Bill To"
      detailsTitle="Invoice Details"
      dateTitle="Invoice Date"
      idTitle="Invoice ID"
      data={item}
      showWarning={showWarning}
      type={DOC_TYPE_INVOICE}
    />
  )
}

export default InvoicePage