import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { CATEGORIES, checkValue, getLocalDateString, ShowNumber } from "../../Helpers/helpers";
import { GenericAccordion } from "../GenericComponents/Accordion/GenericAccordion";
import { InquiryGridItem } from "./InquiryGridItem";
import { Box, Typography } from "@mui/material";
import { commonFontSize, commonFontWeight, regularFontSize } from "../../Helpers/ConstantProperties";
import { InquiryRejectionObj } from "../../Helpers/ExtraProperties";
import LaunchIcon from '@mui/icons-material/Launch';

export const InquiryDetailBody = ({ item }) => {
    var screenShow = {
        show: "d-sm-flex d-none flex-grow-1"
    }

    const statusCategory = CATEGORIES[item.status] ? CATEGORIES[item.status]?.name : InquiryRejectionObj.name;

    let description = (item.description && item.description !== "") ? true : false;

    return (
        <Grid2 className="px-2 d-flex flex-column gap-3 col-12">
            <Grid2 container gap={1} direction={"row"} width={'100%'}>
                <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'Contact Person'} value={checkValue(item.contactPerson)} />
                <InquiryGridItem display={screenShow.show} xl={2} xs={6} title={'E-mail ID'} value={item.contactEmail} />
                <InquiryGridItem display={screenShow.show} xl={3} xs={6} title={'Contact No'} value={item.contactPhone} />
                <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'City'} value={checkValue(item.city)} />
            </Grid2>
            <Grid2 container gap={1} direction={"row"} width={'100%'}>
                <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'Last Update'} value={getLocalDateString(item.lastUpdated)} />
                <InquiryGridItem display={"flex-grow-1"} xl={3} xs={4} title={'Next Follow Up'} value={getLocalDateString(item.followUpDate)} />
                {item.designUrl  && <InquiryGridItem xl={2} display={"flex-grow-1"} title={'Design Link'} value={
                    <Grid2 className="">
                        <a href={item.designUrl} target="blank">
                            <LaunchIcon />
                        </a>
                    </Grid2>
                } />
                }
            </Grid2>
            <Grid2 container gap={1} direction={"row"} width={'100%'}>
                <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'Stage'} value={checkValue(statusCategory)} />
                <InquiryGridItem display={"flex-grow-1"} xl={2} xs={6} title={'Discount'} value={checkValue(`${item.discount}%` || ShowNumber(item.discountPrice, 2, true))} />
                <InquiryGridItem display={"flex-grow-1"} xl={2} xs={6} title={'Source of Lead'} value={checkValue(item.sourceOfLead)} />
            </Grid2>
            <Grid2 className={"d-block d-sm-none"}>
                <GenericAccordion
                    name={
                        <Typography fontWeight={commonFontWeight} fontSize={regularFontSize}>Contact Details</Typography>
                    }
                    AccordionComponent={
                        <Grid2 container className="d-flex justify-content-between" >
                            <InquiryGridItem xs={6} title={'Contact No'} value={item.contactPhone} />
                            <InquiryGridItem xs={6} title={'E-mail ID'} value={item.contactEmail} />
                        </Grid2>
                    }
                />
            </Grid2>
            <Grid2>
                {description && (
                    <Grid2 className={"d-block d-sm-none"}>
                        <GenericAccordion
                            name={
                                <Typography fontWeight={commonFontWeight} fontSize={regularFontSize}>Description</Typography>}
                            AccordionComponent={
                                <Box >
                                    <Typography sx={{ fontSize: commonFontSize }} fontWeight={commonFontWeight} className="text-break" >
                                        {item.description}
                                    </Typography>
                                </Box>

                            }
                        />
                    </Grid2>
                )}
            </Grid2>

            <Grid2>
                {description && (
                    <Grid2 className={"d-none d-sm-block"}>
                        <Typography fontSize={regularFontSize} fontWeight={commonFontWeight} className='mb-1'>
                            Description
                        </Typography>
                        <Typography
                            sx={{ fontSize: commonFontSize }}
                            fontWeight={commonFontWeight - 200}
                            className="text-break display-linebreak pb-2">
                            {item.description}
                        </Typography>
                    </Grid2>
                )}

            </Grid2>

        </Grid2>
    )
}