import Grid2 from "@mui/material/Unstable_Grid2";
import TableHeader from "../CommonComp/TableHeader";
import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import { CGST_HSN_TAX_HEADER, IGST_HSN_TAX_HEADER } from "../../../Helpers/helpers";
import HSNSummary from "./HSNSummary";
import HSNTotalRow from "./HSNTotalRow";

export default function TaxHSNTable() {
    const { context } = useContext(DocumentContext);
    const cData = context.cData;

    const headerList = cData.igstApp ? IGST_HSN_TAX_HEADER : CGST_HSN_TAX_HEADER

    return (
        <Grid2 container className='rounded' border={{ xs: 'none', md: '1px solid lightgray' }}>
            <TableHeader propList={headerList} />

            {
                Object.values(cData.hsnWiseList).map((hsnData, index) => (
                    <Grid2
                        display={{ xs: "none", md: "block" }}
                        xs={12}
                        key={index}
                        textAlign={"center"}
                        border={{ xs: 'none', md: '1px solid lightgray' }}>

                        <HSNSummary hsnData={hsnData} isIGST={cData.igstApp} />
                    </Grid2>
                ))
            }

            <Grid2 xs={12} className="bg-light">
                <HSNTotalRow cData={cData} />
            </Grid2>

        </Grid2>
    )
}