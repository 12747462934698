import React, { useContext, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import InquiryProductDetails from '../../Inquiry/InquiryProductDetails';
import GenericDialog from '../../GenericComponents/Dialog/GenericDialog';
import RequirementRawMaterial from '../RequirementProducts/RequirementRawMaterial'
import { REQUIREMENT, UPDATE_ON_INQUIRY } from '../../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import { AuthContext } from '../../Auth/Auth';
import { FirmContext } from '../../Contexts/FirmContext';
import { useNavigate } from 'react-router-dom';
import InquiryProductHeading from '../../Inquiry/InquiryProductHeading';
import RequirementResponsiveProductContent from './RequirementResponsiveProductContent';
import { GenericActionControl } from '../../GenericComponents/Buttons/GenericActionControl';
import { PROCESS_GROUP_NAME, processPropertyGroup, RM_GROUP_NAME, rmPropertyGroup } from '../../../Helpers/ExtraProperties';
import { DetailPaneContext } from '../../Inquiry/InquiryInformation';
import { getProductHeadingWithoutAction } from '../../../Helpers/helpers';

const RequirementProductTable = ({ data, navigateOnCancel = REQUIREMENT }) => {
  const { token } = useContext(AuthContext);
  const { khID, currentFirm } = useContext(FirmContext);
  const enableAction = useContext(DetailPaneContext)

  const [Component, setComponent] = useState()
  const [Open, setOpen] = useState(false)

  // This is temp work-around for delete all requiremets
  // As the data is updated in memory, we dont have a better
  // way to re-render the UI.
  const [count, setCount] = useState(0);

  const navigate = useNavigate()

  const rawMaterialExtraProps = {
    successMessage: "Raw Material Added",
    updatePropertyListObject: rmPropertyGroup,
    dialogTitle: "Add Raw Material ",
  }

  const processExtraProps = {
    successMessage: "Process Material Added",
    updatePropertyListObject: processPropertyGroup,
    dialogTitle: "Add Processes ",
  }

  const successMessage = "Raw Material And Processes Added Successfully"

  const editRequirementToInq = (state, product, propName) => {
    if (state.isGroupEdited) {
      product[propName] = [];
      state[propName].map((item) => product[propName].push(item))
    }
    setOpen(false)
  }

  const onClearRequirement = (product) => {
    delete product[RM_GROUP_NAME]
    delete product[PROCESS_GROUP_NAME]

    // Just a work-around to re-render the UI
    setCount(count + 1)
  }

  const handleSubmit = async () => {
    return await serviceHelpers.updateInquiry(
      token,
      khID,
      data,
      data.id
    )
  }

  const filteredProducts = [];
  const productsWithRawMaterial = [];

  data.products?.forEach((product) => {
    if (product[RM_GROUP_NAME] || product[PROCESS_GROUP_NAME]) {
      productsWithRawMaterial.push(product);
    }
    else {
      filteredProducts.push(product);
    }
  });

  var inquiryProductHeadingObject = { addButtonExtraProps: {} }

  if (enableAction?.disableAction) inquiryProductHeadingObject.heading = getProductHeadingWithoutAction(currentFirm.editSaleRate)

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12}>
        <InquiryProductDetails
          data={{ ...data, products: filteredProducts }}
          addButtonExtraProps={
            { getSelectedItem: () => setOpen(true), enableAdd: true }
          }
          HeadingComponent={
            (props) => <InquiryProductHeading {...props} {...inquiryProductHeadingObject} />
          }
          ResponsiveContentComponent={(props) => <RequirementResponsiveProductContent
            editRequirementToInq={editRequirementToInq}
            rawMaterialExtraProps={rawMaterialExtraProps}
            processExtraProps={processExtraProps}
            {...props}
            products={data.products}
            setOpen={setOpen}
            setComponent={setComponent}
            editSalerate={currentFirm.editSaleRate}
          />}
        />
        <GenericDialog
          title={Component?.extraProperties.dialogTitle}
          open={Open}
          setOpen={setOpen}
          content={Component?.Component}
        />
      </Grid2>


      <Grid2 xs={12}>
        <DetailPaneContext.Provider value={{ disableAction: true }}>
          <RequirementRawMaterial
            data={{ ...data, products: productsWithRawMaterial }}
            editRequirementToInq={editRequirementToInq}
            onClearRequirement={onClearRequirement}
            rawMaterialExtraProps={rawMaterialExtraProps}
            processExtraProps={processExtraProps}
          />
        </DetailPaneContext.Provider>
      </Grid2>
      <Grid2 xs={12}>
        <GenericActionControl
          buttonText={"save"}
          inValidateQueryKey={UPDATE_ON_INQUIRY}
          navigateOnCancel={navigateOnCancel}
          onCancel={() => navigate(navigateOnCancel)}
          successMessage={successMessage}
          queryFunction={handleSubmit}
        />
      </Grid2>

    </Grid2>
  );
}

export default RequirementProductTable;
