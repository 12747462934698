import React, { useContext } from 'react';
import { BALANCE, INQUIRY_STORE_ACCOUNT_ID, PRODUCT_TYPE_RAW, PRODUCTION, ProductionTable, RMLIST, STOCK_MANAGEMENT, UPDATE_ON_INQUIRY } from '../../Helpers/ConstantProperties';
import { rmAccordionName, rmBtnText, rmDialogTitle, rmHoldProperty, rmSuccessMessage } from './StockDashboard';
import { CircularProgress, MenuItem } from '@mui/material';
import { ProductDialog } from './ProductDialog';
import { CheckforRelease, deepCopyObject, getRequirementArray, getStockValue } from '../../Helpers/helpers';
import { useQuery } from '@tanstack/react-query';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { PRODUCT_TAG } from '../../Helpers/ExtraProperties';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { StockInput } from './StockInput';

const StockMenu = ({ item, handleMenuItemClick, handleClose }) => {
    const { token, currentUserObject } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    item.checked = true
    item = deepCopyObject(item)
    //that is for reseting when we firstly go the raw material check and then go back 
    //to the  saving all teh material 

    const { data: productBalanceObject, isLoading, error } = useQuery({
        queryFn: async () => {
            return await serviceHelpers.getBalancePerInquiryAndStore(token, khID, {
                accountID: item.id,
                date: Date.now(),
                tag: PRODUCT_TAG,
            })
        },
        queryKey: [
            currentUserObject.uid,
            khID,
            UPDATE_ON_INQUIRY,
            BALANCE,
            PRODUCT_TAG,
            INQUIRY_STORE_ACCOUNT_ID,
            item.id
        ]
    })

    if (isLoading) {
        return <CircularProgress />
    }

    if (error) {
        return <GenericErrorComponent error={error} />
    }

    const checkReleaseObject = CheckforRelease(item, productBalanceObject.storeBalancePerProduct, PRODUCT_TYPE_RAW);

    let disableButton = false;

    // Determine if the button should be disabled based on checkReleaseObject

    if ((checkReleaseObject.checkforZero && checkReleaseObject.checkForEmptyStore) || !checkReleaseObject.checkforZero) {
        disableButton = true;
    }

    if (item[rmHoldProperty]) {
        disableButton = true;
    }

    const content = <ProductDialog
        data={item}
        heading={ProductionTable}
        onCancel={handleClose}
        successMessage={rmSuccessMessage}
        AccordionName={rmAccordionName}
        buttonText={rmBtnText}
        getProductArray={getRequirementArray}
        reqPropName={RMLIST}
        note={"Raw Material Relesed, Status changed to In Production"}
        nextStatus={PRODUCTION}
        getValue={getStockValue}
        inValidateQueryKey={UPDATE_ON_INQUIRY}
        navigateOnCancel={STOCK_MANAGEMENT}
        productType={PRODUCT_TYPE_RAW}
        productBalanceObject={productBalanceObject.balancePerInquiry}
        disabled={disableButton}
        RenderedComponent={StockInput}
    />

    const onClick = () => handleMenuItemClick(content, rmDialogTitle)

    return (
        <MenuItem disabled={disableButton} onClick={onClick}>Production Plan</MenuItem>
    )
};

export default StockMenu;