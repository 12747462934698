import { useState } from "react";
import GenericHeader from "../GenericComponents/Header/GenericHeader";
import DisplayLeadsInStore from "./DisplayLeadsInStore";
import Grid2 from "@mui/material/Unstable_Grid2";

function LeadInStore({ leads = {}, Dashboardtitle }) {
    const [searchString, setSearchString] = useState('');

    const getSearchableValue = (current) => {
        return (
            current.name + " "
            + current.city + " "
            + current.phoneNumber + " "
            + current.email + " "
            + current.contactPerson + " "
            + current.customerName
        )
    }

    return (
        <>
            <GenericHeader
                title={Dashboardtitle}
                textFieldLabel="Search by Company Name, Contact Person,Customer Name, Phone Number or Source of Lead"
                setSearchString={setSearchString}
                searchString={searchString}
            />
            <Grid2 container px={2}>
                {Object.entries(leads).map(([key, value], index) => <DisplayLeadsInStore
                    searchString={searchString}
                    getSearchableValue={getSearchableValue}
                    key={index}
                    leadID={key}
                    leadDetails={value}
                />)}
            </Grid2>
        </>
    )
}

export default LeadInStore;
