import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    commonFontSize,
    extraSmallFontSize,
    PRIMARY_COLOR
} from "../../../Helpers/ConstantProperties";
import { ShowNumber } from "../../../Helpers/helpers";

export const TableCell = ({ main, sub }) => {
    return (
        <>
            <Typography fontSize={commonFontSize}>
                {main}
            </Typography>
            <Typography fontSize={extraSmallFontSize} color={PRIMARY_COLOR}>
                {sub}
            </Typography>
        </>
    )
}

export default function ItemDetails({ item }) {
    return (
        <>
            <Grid2
                xs={12}
                md={4}
                textAlign={{ xs: "left", md: "center" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <TableCell main={item.name} sub={item.productdescription} />

            </Grid2>

            <Grid2
                xs={12}
                md={1.5}
                textAlign={{ xs: "left", md: "center" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <TableCell main={item.productHSNcode} sub={"GST Rate: " + item.GSTRate + "%"} />
            </Grid2>

            <Grid2
                xs={12}
                md={1.5}
                textAlign={{ xs: "left", md: "center" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <TableCell
                    main={ShowNumber(item.units, 2)}
                    sub={item.unit} />
            </Grid2>

            <Grid2
                xs={12}
                md={2}
                paddingRight={2}
                textAlign={{ xs: "left", md: "right" }}
                borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                <TableCell
                    main={ShowNumber(item.saleRate, 2, true)}
                    sub={"Inclusive: " + ShowNumber(item.inclusiveRate, 2, true)} />
            </Grid2>


            <Grid2
                xs={12}
                md={3}
                paddingRight={2}
                textAlign={{ xs: "left", md: "right" }} >

                <TableCell
                    main={ShowNumber(item.taxableAmount, 2, true)}
                    sub={item.gstValueString} />
            </Grid2>
        </>
    )
}