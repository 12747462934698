import { Button, Typography } from "@mui/material";
import { addDaysToToday, CATEGORIES, checkValue, DecodeServerError, getCommentForCustomer } from "../../Helpers/helpers";
import { CONVERTED, FIVE_DAYS, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { useContext } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { DateContext } from "../Contexts/DateContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserActionContext } from "./SummaryPage";
import { useSnackbar } from "../Contexts/SnackbarProvider";

function DashboardUpdateStatusAction({ item, statusCategory }) {
    const { token } = useContext(AuthContext);
    const { currentFirm } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);
    const { showAssigned } = useContext(UserActionContext);
    const { showSnackbar } = useSnackbar();

    const queryFunction = async () => {

        const update = {
            followUpDate: addDaysToToday(2, currentDate),
            status: statusCategory.nextStatus,
        };

        if (update.status === CONVERTED && item.customerId) {
            await serviceHelpers.updateCustomer(
                token,
                currentFirm.khID,
                { followUpDate: addDaysToToday(FIVE_DAYS, currentDate) },
                item.customerId,
                getCommentForCustomer(item.customerId, FIVE_DAYS)
                , currentDate
            );
        }

        const comment = `Status updated to ${CATEGORIES[statusCategory.nextStatus].name}`;

        const data = await serviceHelpers.updateLeadStatus(
            token, currentFirm.khID, update, item.id, comment, currentDate, item);
        return { id: data.id, comment };
    }

    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: queryFunction,

        onSuccess: (data) => {
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.includes(UPDATE_ON_INQUIRY),
            });
            showSnackbar(`${data.comment}`, 'success');
        },

        onError: (error) => {
            const message = DecodeServerError(error);
            showSnackbar(message, 'error');
        },
    });

    if (showAssigned) {
        const owner = item.assignee ? item.assignee.displayName : "Not Assigned yet";
        return (
            <Typography sx={{ textAlign: { xs: 'left', sm: 'center' }, whiteSpace: 'nowrap' }}>
                {owner}
            </Typography>
        )
    }

    const disabled = CATEGORIES[item.status]?.disableBtn
        ? CATEGORIES[item.status]?.disableBtn(item)
        : false;

    return (
        <Button
            variant="outlined"
            disabled={disabled}
            sx={{ width: '100%', whiteSpace: 'nowrap' }}
            onClick={() => mutate(queryFunction)}>
            {checkValue(statusCategory?.btnText)}
        </Button>
    )
}

export default DashboardUpdateStatusAction;
