import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import { defaultFormatTableValues } from "../../../Helpers/helpers";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ResponsiveItem } from "./ResponsiveItem";
import TableHeader from "../CommonComp/TableHeader";
import TotalRow from "./TotalRow";

export default function ItemsFormat() {
    const { context } = useContext(DocumentContext);
    const cData = context.cData;

    const propList = defaultFormatTableValues;

    return (
        <Box
            className='rounded'
            border={{ xs: 'none', md: '1px solid lightgray' }}
        >
            <Grid2 container>
                <TableHeader propList={propList} />

                {
                    cData?.items.map((item) => (
                        <Grid2
                            key={item.id}
                            xs={12}
                            border={{ xs: 'none', md: '1px solid lightgray' }}>

                            <Grid2 container paddingY={1}>
                                <ResponsiveItem propList={propList} item={item} />
                            </Grid2>

                        </Grid2>
                    ))
                }

                <Grid2 xs={12} className="bg-light">
                    <TotalRow />
                </Grid2>
            </Grid2>
        </Box>
    )
}