import { Route, Routes } from "react-router-dom";
import CreateFirmForm from '../Firm/CreateFirmForm';
import CreateCustomerForm from "../Party/CreateCustomerForm"
import Users from "../User/User";
import GenericLayout from "../GenericComponents/Layout/GenericLayout";
import PageNotFound from "../Errors/PageNotFound";
import { UserRoles } from "../UserRoles/UserRoles";
import Joinfirm from "../Firm/JoinFirm";
import JoinFirmMessage from "../Firm/JoinFirmMessage";
import { EditUserRole } from "../UserRoles/EditUserRoles";
import FirmListSelected from "../Dashboard/FirmListSelected";
import { EditParty } from "../Party/EditParty";
import InquirySources from "../Sources/InquirySources";
import { BulkPartyCreation } from "../GenericComponents/XlsxFileReader/BulkPartyCreation";
import { BulkProductCreation } from "../GenericComponents/XlsxFileReader/BulkProductCreation";
import {
    MANAGE_CUSTOMERS,
    NEW_LEAD,
    CREATE_CONTACTS,
    CREATEFIRM,
    CREATEPARTY,
    REQUIREMENT,
    JOINFIRM,
    NEW_USERS,
    NEW_PRODUCTS,
    NEW_USERROLES,
    JOINFIRMMSG,
    EDITUSERROLE,
    EDITPARTY,
    EDITPRODUCT,
    NEW_INQUIRY_SOURCE,
    INQUIRY_STATE,
    CREATE_NEW_INQUIRY,
    FIRMS_CHECK,
    HOME,
    CREATEPRODUCT,
    CREATEUSERROLE,
    CREATEINQUIRYSOURCE,
    EDIT_INQUIRY,
    NEW_CONTACTS,
    FIX_BREAKING_CHANGE,
    ADD_VALUES_TO_FIRM,
    EDITINQUIRYSOURCE,
    REJECTED_INQUIRY,
    GENERATE_QUOTATION,
    CLOSED_INQUIRY,
    GENERATE_REQUIREMENT,
    QUOTATION,
    NEW_PRODUCTION,
    NEW_ORDER,
    CREATE_NEW_ORDER,
    VIEWORDER,
    EDITORDER,
    REPORTS,
    PRODUCT_TYPE_FINISHED,
    NEW_PRODUCTS_RAW,
    PRODUCT_TYPE_PROCESS,
    NEW_PRODUCTS_PROCESS,
    PRODUCT_TYPE_RAW,
    CUSTOM_FIRMS,
    INWORD_RM_STOCK,
    NEW_DISCOUNT,
    CREATE_DISCOUNT,
    STOCK_MANAGEMENT,
    CREATE_USER,
    CONVERTED,
    QUOTED,
    ONBOARDING,
    ADD_DESIGN_FILE,
    NEW_QUALITY_CHECK,
    OPEN_INQUIRIES,
    OPEN,
    STOCK_STMT,
    MANAGE_VENDORS,
    CREATEVENDOR,
    VIEWPRODUCTION,
    DESIGN,
    MARK_QUALITY,
    GENERATE_INVOICE,
    REASSIGN_INQUIRY,
    DELIVERY_AND_INVOICE_MANAGEMENT,
    INQUIRY_DETAIL,
    CUSTOMER_FOLLOWUP,
    CREATE_IN_CHALLAN,
    CREATE_OUT_CHALLAN,
    INWORD_CHALLAN,
    OUTWORD_CHALLAN,
    DISPLAY_CHALLAN,
    NEW_INWORD_CHALLAN,
    NEW_OUTWORD_CHALLAN,
    EDIT_FIRM,
    ADD_BANK,
    NOTIFICATION_PAGE,
    BANK_DETAIL,
    EDIT_BANK_ACCOUNT,
    UPDATE_ON_PRODUCT,
    ASSIGN_GOODS,
    CREATE_INTERNAL_ORDER,
    SET_QUOTE_TERMS,
    WA_ALL_MESSAGE
} from "../../Helpers/ConstantProperties";
import CreateProduct from "../Product/CreateProduct";
import CreateUserRole from "../UserRoles/CreateUserRole";
import CreateInquirySource from "../Sources/CreateInquirySource";
import { EditInquiry } from "../Inquiry/EditInquiry";
import Contacts from "../Contacts/Contacts";
import { ManageProduct } from "../Product/ManageProduct";
import CreateContactForm from "../Contacts/CreateContactForm"
import FixBreakingChange from "../Firm/FixBreakingChange";
import EditFirm from "../Firm/EditFirm";
import { EditInquirySource } from "../Sources/EditInquirySource";
import GenerateRequirement from "../Requirement/GenerateRequirement";
import CreateOrder from "../Order/CreateOrder";
import CreateOpenInquiry from "../Inquiry/CreateOpenInquiry";
import ViewOrderComponent from "../Order/ViewOrderComponent";
import { EditOrderComponent } from "../Order/EditOrderComponent";
import Reports from "../Reports/Report";
import CreateDefaultFirms from "../Firm/CreateDefaultFirms";
import Discount from "../Discount/Discount";
import CreateDiscount from "../Discount/CreateDiscount";
import CreateUser from "../User/CreateUser";
import { DashBoardController } from "../Dashboard/DashBoardController";
import { CLOSED_INQUIRY_TAG, INQUIRY_REJECT_TAG } from "../../Helpers/ExtraProperties";
import ProductionDashboard from "../Production/ProductionDashboard";
import StockBalance from "../Product/StockBalance";
import Customers from "../Party/Customers";
import Vendors from "../Party/Vendors";
import CreateVendorForm from "../Party/CreateVendorForm";
import ProductionDetails from "../Production/ProductionDetails";
import InquiryInStock from "../Inquiry/InquiryInStock";
import InwordRMStock from "../Product/InwordRMStock";
import { checkInquiryHasProcesses, InqFilterForManageRM, InqFilterForOrder, InqFilterForQuotation } from "../../Helpers/helpers";
import SummaryPage from "../Dashboard/SummaryPage";
import QuotationPage from "../Quotation/QuotationPage";
import MarkQuality from "../Qualikty Check/MarkQuality";
import InvoicePage from "../TaxInvoice/InvoicePage";
import { inquiryRouteDetails, orderRouteDetails } from "../../Helpers/ConstantProperties";
import { InquiryInformation } from "../Inquiry/InquiryInformation";
import { CustomerDashboard } from "../Customer/CustomerDashboard";
import CreateChallan from "../Challan/CreateChallan";
import DisplayChallan from "../Challan/DisplayChallan";
import Challan from "../Challan/Challan";
import AddBankAccount from "../Bank/AddBankAccount";
import BankAccounts from "../Bank/BankAccounts";
import { EditBankAccount } from "../Bank/EditBankAccount";
import NotificationPage from "../Nav/NotificationPage";
import { StoreBalanceStock } from "../Stock/StoreBalanceStock";
import QualityStockBalance from "../Qualikty Check/QualityStockBalance";
import { CreateInternalOrder } from "../Order/CreateInternalOrder";
import AssignGoods from "../AssignFinishGood/AssignGoods";
import SetQuoteTerms from "../Quotation/SetQuoteTerms";
import AllWAChat from "../Messages/AllWAChat";

function Routers() {
    return (
        <Routes>
            <Route
                path={FIRMS_CHECK}
                element={
                    <GenericLayout
                        Component={<FirmListSelected />}
                        needsFirmSelected={false}
                    />
                }
            />
            <Route
                path={MANAGE_CUSTOMERS}
                element={
                    <GenericLayout
                        Component={<Customers />}
                    />
                }
            />
            <Route
                path={MANAGE_VENDORS}
                element={
                    <GenericLayout
                        Component={<Vendors />}
                    />
                }
            />
            <Route
                path={NEW_LEAD}
                element={
                    <GenericLayout
                        Component={<DashBoardController />}
                    />
                }
            />
            <Route
                path={HOME}
                element={
                    <GenericLayout
                        Component={<SummaryPage
                            routeDetails={inquiryRouteDetails}
                        />}
                    />
                }
            />
            <Route
                path={CREATEFIRM}
                element={
                    <GenericLayout
                        Component={<CreateFirmForm />}
                        needsFirmSelected={false}
                    />
                }
            />
            <Route
                path={CREATEPARTY}
                element={
                    <GenericLayout
                        Component={<CreateCustomerForm />}
                    />
                }
            />
            <Route
                path={CREATEVENDOR}
                element={
                    <GenericLayout
                        Component={<CreateVendorForm />}
                    />
                }
            />
            <Route
                path={STOCK_STMT}
                element={
                    <GenericLayout Component={<StockBalance />} />
                }
            />
            <Route
                path={INWORD_RM_STOCK}
                element={
                    <GenericLayout Component={<InwordRMStock />} />
                }
            />
            <Route
                path={JOINFIRM}
                element={
                    <GenericLayout
                        Component={<Joinfirm />}
                        needsFirmSelected={false}
                    />
                }
            />
            <Route
                path={NOTIFICATION_PAGE}
                element={
                    <GenericLayout
                        Component={<NotificationPage />}
                    />
                }
            />
            <Route
                path={NEW_USERS}
                element={
                    <GenericLayout
                        Component={<Users />}
                    />
                }
            />
            <Route
                path="*"
                element={
                    <PageNotFound />
                }
            />

            <Route
                path={CREATEPRODUCT}
                element={
                    <GenericLayout
                        Component={<CreateProduct />}
                    />
                }
            />

            <Route
                path={NEW_USERROLES}
                element={
                    <GenericLayout
                        Component={<UserRoles />}
                    />
                }
            />

            <Route
                path={CREATEUSERROLE}
                element={
                    <GenericLayout
                        Component={<CreateUserRole />}
                    />
                }
            />

            <Route
                path={JOINFIRMMSG}
                element={
                    <GenericLayout
                        Component={<JoinFirmMessage />}
                        needsFirmSelected={false}
                    />
                }
            />

            <Route
                path={EDITUSERROLE}
                element={
                    <GenericLayout
                        Component={<EditUserRole />}
                    />
                }
            />

            <Route
                path={EDITPARTY}
                element={
                    <GenericLayout
                        Component={<EditParty />}
                    />
                }
            />

            <Route
                path={EDITPRODUCT}
                element={
                    <GenericLayout
                        Component={<CreateProduct />}
                    />
                }
            />
            <Route
                path={NEW_INQUIRY_SOURCE}
                element={
                    <GenericLayout
                        Component={
                            <InquirySources />}
                    />
                }
            />

            <Route
                path={CREATEINQUIRYSOURCE}
                element={
                    <GenericLayout
                        Component={
                            <CreateInquirySource />}
                    />
                }
            />

            <Route
                path={INQUIRY_STATE}
                element={
                    <GenericLayout
                        Component={<DashBoardController />}
                    />
                }
            />

            <Route
                path={CREATE_NEW_INQUIRY}
                element={
                    <GenericLayout
                        Component={<CreateOpenInquiry />}
                    />
                }
            />

            <Route
                path={EDIT_INQUIRY}
                element={
                    <GenericLayout
                        Component={<EditInquiry />}
                    />
                }
            />

            <Route
                path={VIEWPRODUCTION}
                element={
                    <GenericLayout
                        Component={<ProductionDetails />}
                    />
                }
            />


            <Route
                path={NEW_PRODUCTS}
                element={
                    <GenericLayout
                        Component={<ManageProduct productType={PRODUCT_TYPE_FINISHED} />}
                    />
                }
            />

            <Route
                path={NEW_PRODUCTS_RAW}
                element={
                    <GenericLayout
                        Component={<ManageProduct productType={PRODUCT_TYPE_RAW} navigateTo={NEW_PRODUCTS_RAW} />}
                    />
                }
            />

            <Route
                path={NEW_PRODUCTS_PROCESS}
                element={
                    <GenericLayout
                        Component={<ManageProduct productType={PRODUCT_TYPE_PROCESS} navigateTo={NEW_PRODUCTS_PROCESS} />}
                    />
                }
            />


            <Route
                path={"/BulkPartyCreation"}
                element={
                    <GenericLayout
                        Component={<BulkPartyCreation />}
                    />
                }
            />

            <Route
                path={"/BulkProductCreation"}
                element={
                    <GenericLayout
                        Component={<BulkProductCreation />}
                    />
                }
            />

            <Route
                path={NEW_CONTACTS}
                element={
                    <GenericLayout
                        Component={<Contacts />}
                    />
                }
            />

            <Route
                path={CREATE_CONTACTS}
                element={
                    <GenericLayout
                        Component={<CreateContactForm />}
                    />
                }
            />

            <Route
                path={FIX_BREAKING_CHANGE}
                element={
                    <GenericLayout
                        Component={<FixBreakingChange />}
                    />
                }
            />

            <Route
                path={ADD_VALUES_TO_FIRM}
                element={
                    <GenericLayout
                        Component={<EditFirm />}
                    />
                }
            />

            <Route
                path={EDIT_FIRM}
                element={
                    <GenericLayout
                        Component={<EditFirm showDefault={true} />}
                    />
                }
            />

            <Route
                path={EDITINQUIRYSOURCE}
                element={
                    <GenericLayout
                        Component={<EditInquirySource />}
                    />
                }
            />
            <Route
                path={GENERATE_QUOTATION}
                element={
                    <GenericLayout
                        Component={<QuotationPage />}
                    />
                }
            />

            <Route
                path={REJECTED_INQUIRY}
                element={
                    <GenericLayout
                        Component={<DashBoardController tag={INQUIRY_REJECT_TAG} />}
                    />
                }
            />

            <Route
                path={CLOSED_INQUIRY}
                element={
                    <GenericLayout
                        Component={<DashBoardController tag={CLOSED_INQUIRY_TAG} />}
                    />
                }
            />

            <Route
                path={REQUIREMENT}
                element={
                    <GenericLayout
                        Component={<DashBoardController
                            filterFunction={InqFilterForManageRM}
                            customQueryKey={[UPDATE_ON_PRODUCT, ONBOARDING]} />}
                    />
                }
            />

            <Route
                path={GENERATE_REQUIREMENT}
                element={
                    <GenericLayout
                        Component={<GenerateRequirement />}
                    />
                }
            />

            <Route
                path={SET_QUOTE_TERMS}
                element={
                    <GenericLayout
                        Component={<SetQuoteTerms />}
                    />
                }
            />

            <Route
                path={QUOTATION}
                element={
                    <GenericLayout
                        Component={<DashBoardController
                            filterFunction={InqFilterForQuotation}
                            customQueryKey={[QUOTED]} />}
                    />
                }
            />

            <Route
                path={NEW_PRODUCTION}
                element={
                    <GenericLayout
                        Component={<ProductionDashboard />}
                    />
                }
            />

            <Route
                path={STOCK_MANAGEMENT}
                element={
                    <GenericLayout
                        Component={<InquiryInStock />}
                    />
                }
            />

            <Route
                path={NEW_ORDER}
                element={
                    <GenericLayout
                        Component={<SummaryPage
                            filterFunction={InqFilterForOrder}
                            customQueryKey={[CONVERTED]}
                            routeDetails={orderRouteDetails}
                        />}
                    />
                }
            />

            <Route
                path={ADD_DESIGN_FILE}
                element={
                    <GenericLayout
                        Component={<DashBoardController filterObject={{ status: CONVERTED }} filterFunction={(inquiries) => inquiries.filter(inquiry => checkInquiryHasProcesses(inquiry))} customQueryKey={[DESIGN]} />}
                    />
                }
            />

            <Route
                path={NEW_QUALITY_CHECK}
                element={
                    <GenericLayout
                        Component={<QualityStockBalance />}
                    />
                }
            />

            <Route
                path={DELIVERY_AND_INVOICE_MANAGEMENT}
                element={
                    <GenericLayout
                        Component={<StoreBalanceStock />}
                    />
                }
            />

            <Route
                path={MARK_QUALITY}
                element={
                    <GenericLayout
                        Component={<MarkQuality />}
                    />
                }
            />

            <Route
                path={OPEN_INQUIRIES}
                element={
                    <GenericLayout
                        Component={<DashBoardController filterObject={{ status: OPEN }} customQueryKey={[OPEN]} />}
                    />
                }
            />

            <Route
                path={CREATE_NEW_ORDER}
                element={
                    <GenericLayout
                        Component={<CreateOrder />}
                    />
                }
            />

            <Route
                path={VIEWORDER}
                element={
                    <GenericLayout
                        Component={<ViewOrderComponent />}
                    />
                }
            />

            <Route
                path={EDITORDER}
                element={
                    <GenericLayout
                        Component={<EditOrderComponent />}
                    />
                }
            />

            <Route
                path={REPORTS}
                element={
                    <GenericLayout
                        Component={<Reports />}
                    />
                }
            />

            <Route
                path={CUSTOM_FIRMS}
                element={
                    <GenericLayout
                        Component={<CreateDefaultFirms />}
                        needsFirmSelected={false}
                    />
                }
            />
            <Route
                path={ADD_BANK}
                element={
                    <GenericLayout
                        Component={<AddBankAccount />}
                    />
                }
            />

            <Route
                path={BANK_DETAIL}
                element={
                    <GenericLayout
                        Component={<BankAccounts />}
                    />
                }
            />

            <Route
                path={EDIT_BANK_ACCOUNT}
                element={
                    <GenericLayout
                        Component={<EditBankAccount />}
                    />
                }
            />

            <Route
                path={NEW_DISCOUNT}
                element={
                    <GenericLayout
                        Component={< Discount />}
                    />
                }
            />

            <Route
                path={CREATE_DISCOUNT}
                element={
                    <GenericLayout
                        Component={< CreateDiscount />}
                    />
                }
            />

            <Route
                path={GENERATE_INVOICE}
                element={
                    <GenericLayout
                        Component={<InvoicePage />}
                    />
                }
            />

            <Route
                path={CREATE_USER}
                element={
                    <GenericLayout
                        Component={< CreateUser />}
                        needsFirmSelected={false}
                    />
                }
            />

            <Route
                path={WA_ALL_MESSAGE}
                element={
                    <GenericLayout
                        Component={<AllWAChat />}
                    />
                }
            />

            <Route
                path={REASSIGN_INQUIRY}
                element={
                    <GenericLayout
                        Component={<SummaryPage
                            routeDetails={{ reassignInquiry: true }}
                        />}
                    />
                }
            />

            <Route
                path={INQUIRY_DETAIL}
                element={
                    <GenericLayout
                        Component={<InquiryInformation />}
                    />
                }
            />

            <Route
                path={CUSTOMER_FOLLOWUP}
                element={
                    <GenericLayout
                        Component={<CustomerDashboard />}
                    />
                }
            />

            <Route
                path={CREATE_IN_CHALLAN}
                element={
                    <GenericLayout
                        Component={<CreateChallan type={INWORD_CHALLAN} />}
                    />
                }
            />

            <Route
                path={CREATE_OUT_CHALLAN}
                element={
                    <GenericLayout
                        Component={<CreateChallan type={OUTWORD_CHALLAN} />}
                    />
                }
            />

            <Route
                path={DISPLAY_CHALLAN}
                element={
                    <GenericLayout
                        Component={<DisplayChallan />}
                    />
                }
            />

            <Route
                path={NEW_INWORD_CHALLAN}
                element={
                    <GenericLayout
                        Component={<Challan type={INWORD_CHALLAN} natigateTo={CREATE_IN_CHALLAN} />}
                    />
                }
            />

            <Route
                path={NEW_OUTWORD_CHALLAN}
                element={
                    <GenericLayout
                        Component={<Challan type={OUTWORD_CHALLAN} natigateTo={CREATE_OUT_CHALLAN} />}
                    />
                }
            />

            <Route
                path={CREATE_INTERNAL_ORDER}
                element={
                    <GenericLayout
                        Component={<CreateInternalOrder />}
                    />
                }
            />

            <Route
                path={ASSIGN_GOODS}
                element={
                    <GenericLayout
                        Component={<AssignGoods />}
                    />
                }
            />

        </Routes>
    );
}

export default Routers;
