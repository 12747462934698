export const NUMBERS = "Nos";

export const NEW_CUSTOMER = "newCustomer";

export const UPDAE_AND_NEW = 'updatedOrNew';
export const REMOVED = 'removed'

export const INQUIRY_RAW_MATERIALS = "inquiryRawMaterial"

export const ADMIN_USER_LEVEL_ID = "AdminLevelID";
export const ADMIN_USER_LEVEL_NAME = "Admin";

export const USER_LEVEL_ID = "UserLevelID";
export const USER_LEVEL_NAME = "User";

export const MODERATOR_LEVEL_ID = "ModeratorLevelID";
export const MODERATOR_LEVEL_NAME = "Moderator";

export const UPDATE_ON_PARTY = "UpdateOnParty";
export const PARTIES = "Parties";

export const USERS = "Users";
export const UPDATE_ON_USER = "UpdateOnUser";

export const KHATAVANI = "Khatavani";
export const UPDATE_ON_KHATAVANI = "UpdateOnKhatavani";

export const PRODUCTS = 'Products';
export const UPDATE_ON_PRODUCT = "UpdateOnProduct";

export const USERROLE = "UserRoles";
export const UPDATE_ON_USERROLE = "UpdateOnUser";

export const CUSTOMER = "Customer";
export const UPDATE_ON_CUSTOMER = "UpdateOnCustomer";

export const VENDOR = "Vendor";
export const UPDATE_ON_VENDOR = "UpdateOnVendor";

export const INQUIRY = "Inquiry";
export const UPDATE_ON_INQUIRY = "UpdateOnInquiry";

export const UPDATE_BANK_ACCOUNT = "UpdateBankAccount";

export const BALANCE = "Balance";

export const NOT_AVAILABLE = 'Not available'
export const VIEW_QUOTATION = "ViewQoutation"

export const CREATE_INSPECTIONS = "CreateInspections";
export const DISPLAY_INSPECTIONS = "ViewInspections";


export const INQUIRY_SOURCE = "InquirySource";
export const UPDATE_ON_INQUIRY_SOURCE = "UpdateOnInquirySource";

export const CONTACTS = "Contacts";
export const UPDATE_ON_CONTACTS = "UpdateOnContacts";

export const DISCOUNT = "DISCOUNT";
export const UPDATE_ON_DISCOUNT = "UpdateOnDiscount";

export const CUSTOMER_STATUS = "CustomerStatus"

export const VOUCHER = "Voucher";
export const UPDATE_ON_VOUCHER = "UpdateOnVoucher";

export const ADVANCE_PAYMENT_TERM = "Advanced"
export const TRANSPORT_TERM = "Your scope"

export const IN_STOCK_INQUIRIES = "Stock For Inquiries In Store";

export const HOME = "/";
export const NEW_LEAD = "/Leads";
export const NEW_USERS = "/Users";
export const CREATEFIRM = "/CreateFirm";
export const CREATEPARTY = "/createParty";
export const CREATEVENDOR = "/createVendor";
export const JOINFIRM = "/JoinFirm";
export const NEW_PRODUCTS = "/Products";
export const NEW_PRODUCTS_RAW = "/RawMaterial";
export const NEW_PRODUCTS_PROCESS = "/Processes";
export const NEW_USERROLES = "/UserRoles";
export const CREATEUSERROLE = "/CreateUserRole";
export const JOINFIRMMSG = "/JoinFirmMessage";
export const EDITUSERROLE = "/EditUserRole";
export const EDITPARTY = "/EditParty";
export const EDITPRODUCT = "/EditProduct";
export const CREATEPRODUCT = "/CreateProduct";
export const MANAGE_CUSTOMERS = "/cutomers";
export const CUSTOMER_FOLLOWUP = "/customerFolloup"
export const BANK_DETAIL = "/bankDetail";
export const EDIT_BANK_ACCOUNT = "/editBankAccount";
export const MANAGE_VENDORS = "/vendors";
export const NEW_INQUIRY_SOURCE = "/InquirySource";
export const EDITINQUIRYSOURCE = "/EditInquirySource"
export const CREATEINQUIRYSOURCE = "/CreateInquirySource";
export const INQUIRY_STATE = "/Inquiry";
export const REJECTED_INQUIRY = "/rejectedInquiries";
export const CLOSED_INQUIRY = "/InquiryClose";
export const CREATE_NEW_INQUIRY = "/CreateInquiry";
export const FIRMS_CHECK = "/firms";
export const EDIT_INQUIRY = "/editInquiry"
export const NEW_CONTACTS = "/Contacts";
export const CREATE_CONTACTS = "/CreateContacts";
export const FIX_BREAKING_CHANGE = "/FixBreakingChange";
export const ADD_VALUES_TO_FIRM = "/AddValuesToFirm";
export const EDIT_FIRM = "/editFirm";
export const GENERATE_QUOTATION = "/GenerateQuotation";
export const APPLY_TERMS_AND_CONDITION = "/ApplyTermsAndCondition"
export const REQUIREMENT = "/Requirement";
export const GENERATE_REQUIREMENT = "/GenerateRequirement";
export const SET_QUOTE_TERMS = "/SetQuoteTerms";
export const ADD_DESIGN_FILE = "/AddDesigns";
export const NEW_ORDER = "/Orders";
export const NEW_PRODUCTION = "/Production";
export const STOCK_MANAGEMENT = "/Stock";
export const STOCK_STMT = "/stockbalance";
export const STOCK_STMT_DETAILS = "/stockbalanceDetails";
export const INWORD_RM_STOCK = "/inwordrm";
export const NEW_QUALITY_CHECK = "/QualityCheck";
export const MARK_QUALITY = "/MarkQuality";
export const DELIVERY_AND_INVOICE_MANAGEMENT = "/DeliveryAndInvoiceManagement";
export const REPORTS = "/Reports";
export const QUOTATION = "/quotation";
export const CREATE_NEW_ORDER = "/CreateNewOrder";
export const VIEWORDER = "/ViewOrder";
export const EDITORDER = "/EditOrder";
export const CUSTOM_FIRMS = "/CustomFirms";
export const ADD_BANK = "/addBankAccount"
export const CREATE_DISCOUNT = "/CreateDiscount";
export const NEW_DISCOUNT = "/DiscountSlabs";
export const CREATE_USER = "/CreateUser";
export const WA_ALL_MESSAGE = "/wachatwindow";
export const OPEN_INQUIRIES = "/OpenInquiries";
export const VIEWPRODUCTION = "/ViewProduction";
export const EDITPRODUCTION = "/EditProduction";
export const GENERATE_INVOICE = "/GenerateInvoices";
export const REASSIGN_INQUIRY = "/ReassignInquiry";
export const INQUIRY_DETAIL = "/InquiryDetail";
export const CREATE_IN_CHALLAN = "/CreateInChallan";
export const CREATE_OUT_CHALLAN = "/CreateOutChallan";
export const DISPLAY_CHALLAN = "/DisaplayChallan";
export const NEW_INWORD_CHALLAN = "/InChallan";
export const NEW_OUTWORD_CHALLAN = "/OutChallan";
export const NOTIFICATION_PAGE = "/NotificationPage";
export const VIEW_INSPECTION = "/viewInspection";
export const ASSIGN_GOODS = "/AssignGoods";
export const CREATE_INTERNAL_ORDER = "/InternalOrder";

// WhatsApp Message Status
export const READ = "read";
export const DELIVERED = "delivered";
export const FAILED = "failed";
export const SENT = "sent"

export const MESSAGE_QK = "MESSAGES"
export const WAID_QK = "WAID"

// Inquiry Status
export const ONBOARDING = "onboard";
export const OPEN = "open";
export const CLOSED = "closed";
export const QUOTATION_PENDING = "quotation Needed"
export const QUOTATION_READY = "quotation Ready"
export const REVISE_QUOTATION = "revise qotation"
export const QUOTED = "quoted"
export const CONVERTED = "converted"
export const DESIGN = "design"
export const PRODUCTION = "production"
export const STOCK = "stock";
export const DISPATCH = "dispatch"
export const QUALITY_CHECK = "Quality_Check"
export const INQUIRY_RESOURCE_TAG = "inquiryresource"
export const DEFAULT_STORE_TAG = "store"
export const QC_STORE_TAG = "qualitycheckstore"
export const DISCOUNT_SLAB_TAG = "discountslab"
export const GENERATEQUOTATION = "GenerateQuotation"
export const GENERATEBOM = "GenerateBOM"
export const ACTION = "Action"
export const FIXED_AMOUNT_ID = 'fixed_amount'
export const NO_DISCOUNT_ID = 'no_discount'
// Order Status
export const INACTIVE = "inactive";
export const ACTIVE = "active";
export const COMPLETED = "completed";
export const PASS = 'PASS';
export const FAIL = 'FAIL';
export const PENDING = 'PENDING';
export const IN_PROGRESS = 'inProgress';
export const QUALITY = 'Quality';

// Order Category
export const ORDER_CATEGORY = {
  [INACTIVE]: {
    name: 'Inactive',
    color: 'secondary.main',
    nextStatus: ACTIVE
  },
  [ACTIVE]: {
    name: 'Active',
    color: 'warning.main',
    nextStatus: COMPLETED
  },
  [COMPLETED]: {
    name: 'Completed',
    color: 'success.main',
  },
}

export const COLOR_RED = '#E43131';
const COLOR_YELLOW = '#D69B03';
const COLOR_TEAL = '#067279';
const COLOR_PURPLE = '#3E008D';
export const PRIMARY_COLOR = '#1976d2';
export const SECONDARY_COLOR = '#fff';
export const GREEN_BUTTON = '#01AF5C';
export const BLUE_BUTTON = '#0578BC';
export const SUBTEXT_COLOR = '#0578BC';
export const TITLE_COLOR = '#004D7C';
export const TABLE_HEADER_COLOR = '#2887BF'
export const COLOR_GREEN = "#00FF00";
export const ADD_PROPERTY_GROUP_COLOR = "#EBEBEB"
export const DEFAULT_TEXT_COLOR = '#212121';
export const RED_BUTTON = '#E43131'
export const LIGHT_GREY = '#f5f5f5';
export const MEDIUM_GREY = '#f6f6f6';
export const DARK_GREY = '#f9f9f9';
export const NEUTRAL = '#D3D3D3';

export const gradientColors = [
  'linear-gradient(135deg, white 50%, rgba(168,126,235,1) 10%, rgba(2,139,117,1) 98%)',
  'linear-gradient(135deg, white 50%, rgba(248,112,96,1) 10%, rgba(77,0,89,1) 98%)',
  'linear-gradient(135deg, white 50%, rgba(9,185,193,1) 10%, rgba(77,0,89,1) 98%)',
  'linear-gradient(135deg, white 50%, rgba(126,156,235,1) 10%, rgba(71,8,134,1) 98%)',
];

export const gradientMenuColors = [
  'linear-gradient(135deg, rgba(168,126,235,1) 10%, rgba(2,139,117,1) 98%)',
  'linear-gradient(135deg, rgba(248,112,96,1) 10%, rgba(77,0,89,1) 98%)',
  'linear-gradient(135deg, rgba(9,185,193,1) 10%, rgba(77,0,89,1) 98%)',
  'linear-gradient(135deg, rgba(126,156,235,1) 10%, rgba(71,8,134,1) 98%)',
];

export const INQ_OVERDUE = "overdue";
export const INQ_TODAY = "today";
export const INQ_OPEN = "open";
export const INQ_ONTRACK = "ontrack"
export const INQ_CLOSED = "closed"
export const INQ_REJECTED = "rejected";
export const Unknown = "unknown"

export const STATUS_UPDATED = "STATUS_CHANGE";
export const COMMENT_UPDATED = "COMMENT_CHANGE";

export const SESSION_STORAGE_KEY_FIRM = "selectedFirm";
export const SESSION_STORAGE_FIRM_LIST = "UserFirmList";

export const CUSTOMER_DROPDOWN = 'CustomerDropDown';
export const PRODUCTS_DROPDOWN = 'productsDropdown';
export const INQUIRY_SOURCE_DROPDOWN = 'inquirySourceDropdown';
export const USER_ROLES_DROPDOWN = 'userRolesDropdown';
export const USERS_DROPDOWN = 'usersDropdown';
export const ADD_PROPERTY = "addProperty";
export const VENDOR_DROPDOWN = 'VendorDropDown';
export const DISCOUNT_SLAB_DROPDOWN = 'DiscountSlabDropDown';

export const STATUS_OPTIONS = {
  [INQ_OVERDUE]: COLOR_RED,
  [INQ_TODAY]: COLOR_YELLOW,
  [INQ_OPEN]: COLOR_TEAL,
  [INQ_ONTRACK]: COLOR_PURPLE,
  [INQ_REJECTED]: COLOR_RED,
  [INQ_CLOSED]: COLOR_GREEN,
  [Unknown]: NEUTRAL
};

export const REPORT_CARD_GRADIENT = {
  [OPEN]: {
    name: 'Open Inquiry',
    gradiantColor: 'linear-gradient(135deg, #FFB6C1 30%, #FFA07A 90%)',
    detailText: (data) => data.length
  },
  [CONVERTED]: {
    name: 'Overall Conversion',
    gradiantColor: 'linear-gradient(120deg, #00CED1 30%, #4682B4 100%)',
    detailText: (data) => data.length
  },
  [QUOTED]: {
    name: 'Quoted Inquiry',
    gradiantColor: 'linear-gradient(150deg, #1E90FF 40%, #00BFFF 100%)',
    detailText: (data) => data.length
  },
};

export const inquiryReportColors = ['#FF5733', '#33FF57', '#3357FF']
export const ReasonWiseRejectedInquiryReportColors = ['#FF5733', '#33FF57', '#3357FF'];
export const sourceWiseReportColors = ['#FF5733', '#33FF57', '#3357FF'];

export const ADD_PROPS = "Add_Property";
export const REMOVE_PROPS = "Remove_Property";
export const AUTO_FILL = "AUTO_FILL"

export const DOC_INVOICE = "InvoiceFormat";
export const DOC_QUOTE = "QuotationFormat";
export const DOC_BOM = "BomFormat";

export const NA = "NA"
export const STRING = "string"
export const EMPTY = ""
export const NOS = "Nos"

export const drawerWidth = 240;

export const commonFontSize = '0.875rem';
export const regularFontSize = '1rem';
export const smallFontSize = '0.9rem';
export const extraSmallFontSize = '0.7375rem';
export const largeFontSize = '1.125rem';
export const extraLargeFontSize = '2rem'

export const commonFontWeight = '700'

export const STATE_UPDATE_RESOURCE_ID = "StateUpdate";
export const STATE_UPDATE_RESOURCE_NAME = "Inquiry State Update";
export const STATE_UPDATE_RESOURCE_TAG = "updatetxn"
export const STATE_UPDATE_RESOURCE_UNIT = NUMBERS;

export const BATCH_STATE_ACCOUNT_ID = 'BatchStateAccountID';

export const INPROGRESS_INQUIRY_RESOURCE_ID = "inprogressInquiryId";
export const INPROGRESS_INQUIRY = "Inprogress Inquiry";

export const INTERNAL_PROCESS = "Internal Process";
export const EXTERNAL_PROCESS = "External Process";

export const PRODUCT_STATE = "productState"

export const INQUIRY_STATUS_RESOURCES = [
  { id: INPROGRESS_INQUIRY_RESOURCE_ID, name: INPROGRESS_INQUIRY, tag: INQUIRY_RESOURCE_TAG, unit: NUMBERS, known: true, entityPublicAccess: true },
]

export const INQUIRY_STORE_ACCOUNT_ID = "defaultStore";
export const INQUIRY_STORE_ACCOUNT = "Store";

export const QC_STORE_ACCOUNT_ID = "QCStore";
export const QC_STORE_ACCOUNT = "Quality Check Store";

export const COMMON_BATCH = "common";
export const INQUIRY_VOUCHER_TYPE = "Referance";

export const INQUIRY_PRODUCTS = 'Inquiry_products';

export const RAW_MATERIAL = "RawMaterial";

export const RMLIST = "rmlist"

export const PROCESSESS = "processes"

export const CODE_IP = "IPCode";

export const LABOUR = "Labour Cost";

export const PERFORMA_INVOICE = "Performa Invoice";
export const DOC_TYPE_INVOICE = 'Invoice Document';

export const EIGHTEEN_GST = 18;

export const FIVE_DAYS = 5;

export const FIFTEEN_DAYS = 15;

export const INSPECTION_REPORT_LIST = "inspectionReport";

export const REFERANCE_ID = '0cf01b4f-fd97-4112-9627-27a815dcf249';
export const REFERANCE = 'Referance';
export const INTERNAL_ORDER = "Internal Order"

export const defaultSources = [
  {
    id: "indiamartbuylead",
    name: "Indiamart",
    type: ["B"],
    tag: INQUIRY_SOURCE,
    entityPublicAccess: true
  },
  {
    id: "indiamartdirectcall",
    name: "Direct Call - Indiamart",
    type: ["W"],
    tag: INQUIRY_SOURCE,
    entityPublicAccess: true
  },
  {
    id: "indiamartdirectcallpns",
    name: "PNS Call - Indiamart",
    type: ["P"],
    tag: INQUIRY_SOURCE,
    entityPublicAccess: true
  },
  {
    id: "indiamartdirectcallwa",
    name: "WhatsApp - Indiamart",
    type: ["WA"],
    tag: INQUIRY_SOURCE,
    entityPublicAccess: true
  },
  {
    id: "catalogviewlead",
    name: "Catalog View - Indiamart",
    type: ["V", "BIZ"],
    tag: INQUIRY_SOURCE,
    entityPublicAccess: true
  },
  { id: 'c983c38c-1e80-4d6e-933f-4bcd54b42a3d', name: 'JustDial', tag: INQUIRY_SOURCE, entityPublicAccess: true },
  { id: 'b70e71db-ec49-4822-b3e6-54b23b5096a1', name: 'TradeIndia', tag: INQUIRY_SOURCE, entityPublicAccess: true },
  { id: '09825f3b-f3d8-495b-88c4-d14e2fa3d265', name: 'Google', tag: INQUIRY_SOURCE, entityPublicAccess: true },
  { id: '5c4cf073-287f-47a4-b4c8-4ef7f891bb6b', name: 'Facebook', tag: INQUIRY_SOURCE, entityPublicAccess: true },
  { id: 'c8f8a2b4-d5cb-44a5-88f7-7bba2bb0f6b1', name: 'Email', tag: INQUIRY_SOURCE, entityPublicAccess: true },
  { id: REFERANCE_ID, name: REFERANCE, tag: INQUIRY_SOURCE, entityPublicAccess: true }
];

export const DELETE_FIELD = 'delete_this_field_from_database';

export const QUOTATION_SERIES_PREFIX = "Q";
export const INVOICE_SERIES_PREFIX = "IN"
export const CHALLAN_SERIES_PREFIX = "C";
export const ORDER_SERIES_PREFIX = "PO";
export const INW_STK_PREFIX = "INW_STK";

export const PRODUCT_TYPE_FINISHED = "Finished Goods"
export const PRODUCT_TYPE_RAW = "Raw Material"
export const PRODUCT_TYPE_PROCESS = "Work Process"
export const PRODUCT_TYPE_CUSTOM = "Custom Product"

export const CHECK_ACTIONS = "Actions"
export const QUOTATION_FORMAT_DEFAULT_VALUE = "defaultFormat";
export const FORMAT_ONE = "Format 1"

export const PRODUCT_NAME = 'Product Name';
export const PRODUCT_CODE = 'Product Code';
export const QTY = 'Qty';
export const RAW_MATERIAL_TITLE = 'Raw Material';
export const PRICE = 'Price';
export const Units = "Units";
export const PROCESS = "Process"
export const SALE_RATE_HEAD = "Sale Rate"
export const QCNAME = "QcName"
export const DIMENTION = "Dimention"
export const PARAMETER = "Parameter"
export const TOLERANCE = "Tolerance"

export const NO_DISCOUNT = {
  id: NO_DISCOUNT_ID,
  name: 'No Discount',
  discountRate: 0
}

export const FIXED_DISCOUNT = {
  id: FIXED_AMOUNT_ID,
  name: 'Fixed Amount',
  discountRate: 0,
}

export const APPROVED = "Approved";
export const NOT_APPROVED = "Not Approved";

export const ProductionTable = [
  { label: 'Required Quantity', lg: 1.5, xs: 12 },
  { label: 'Remaining Quantity', lg: 1.5, xs: 12 },
  { label: 'Finished Quantity', lg: 4, xs: 12 },
]

const UNASSIGNED_INQUIRY_ID = 'unassignes_id'

export const UNASSIGNED_INQUIRY = {
  id: UNASSIGNED_INQUIRY_ID,
  displayName: 'Assigned To Nobody'
}

export const ACCOUNT_USER_LEVEL_ID = 'Accountant';
export const ACCOUNT_USER_LEVEL = 'Accountant';

export const OPERATOR_USER_LEVEL_ID = 'Operator';
export const OPERATOR_USER_LEVEL = 'Operator';

export const STORE_MANAGER_USER_LEVEL_ID = 'Store_Manager_ID';
export const STORE_MANAGER_USER_LEVEL = 'Store Manager';

export const PRODUCTION_MANAGER_USER_LEVEL_ID = 'Production_Manager_ID';
export const PRODUCTION_MANAGER_USER_LEVEL = 'Production Manager';

export const SALES_MANAGER_USER_LEVEL_ID = 'Sales_Manager_ID';
export const SALES_MANAGER_USER_LEVEL = 'Sales Manager';

export const DESIGN_MANAGER_USER_LEVEL_ID = 'Design_Manager_ID';
export const DESIGN_MANAGER_USER_LEVEL = 'Design Manager';

export const QC_MANAGER_USER_LEVEL_ID = 'Qc_Manager_ID';
export const QC_MANAGER_USER_LEVEL = 'QC Manager';

export const ACCOUNT_LEVEL = {
  id: ACCOUNT_USER_LEVEL_ID,
  name: ACCOUNT_USER_LEVEL,
  known: true
}

export const OPERATOR_LEVEL = {
  id: OPERATOR_USER_LEVEL_ID,
  name: OPERATOR_USER_LEVEL,
  known: true
}

export const STORE_MANAGER_LEVEL = {
  id: STORE_MANAGER_USER_LEVEL_ID,
  name: STORE_MANAGER_USER_LEVEL,
  known: true
}

export const PRODUCTION_MANAGER_LEVEL = {
  id: PRODUCTION_MANAGER_USER_LEVEL_ID,
  name: PRODUCTION_MANAGER_USER_LEVEL,
  known: true
}

export const SALES_MANAGER_LEVEL = {
  id: SALES_MANAGER_USER_LEVEL_ID,
  name: SALES_MANAGER_USER_LEVEL,
  known: true
}

export const DESIGN_MANAGER_LEVEL = {
  id: DESIGN_MANAGER_USER_LEVEL_ID,
  name: DESIGN_MANAGER_USER_LEVEL,
  known: true
}

export const QC_MANAGER_LEVEL = {
  id: QC_MANAGER_USER_LEVEL_ID,
  name: QC_MANAGER_USER_LEVEL,
  known: true
}

export const QC_STORE = {
  id: QC_STORE_ACCOUNT_ID,
  name: QC_STORE_ACCOUNT,
  tag: QC_STORE_TAG,
  known: true,
  entityPublicAccess: true,
  effectAccess: [STORE_MANAGER_USER_LEVEL_ID, PRODUCTION_MANAGER_USER_LEVEL_ID, QC_MANAGER_USER_LEVEL_ID]
}

export const DEFAULT_STORE = {
  id: INQUIRY_STORE_ACCOUNT_ID,
  name: INQUIRY_STORE_ACCOUNT,
  tag: DEFAULT_STORE_TAG,
  known: true,
  entityPublicAccess: true,
  effectAccess: [STORE_MANAGER_USER_LEVEL_ID, PRODUCTION_MANAGER_USER_LEVEL_ID, QC_MANAGER_USER_LEVEL_ID]
}

export const AccountsToCreate = [DEFAULT_STORE, QC_STORE, ...defaultSources]


export const userLevels = [ACCOUNT_LEVEL, OPERATOR_LEVEL, STORE_MANAGER_LEVEL, PRODUCTION_MANAGER_LEVEL, SALES_MANAGER_LEVEL, DESIGN_MANAGER_LEVEL, QC_MANAGER_LEVEL];

export const ResourcesToCreate = [{
  id: STATE_UPDATE_RESOURCE_ID,
  name: STATE_UPDATE_RESOURCE_NAME,
  tag: STATE_UPDATE_RESOURCE_TAG,
  unit: STATE_UPDATE_RESOURCE_UNIT,
  entityPublicAccess: true,
  effectAccess: userLevels.map((level) => level.id)
}]

export const orderRouteDetails = {
  buttonText: "New Order",
  headerTitle: "Order Dashboard",
  navigateTo: CREATE_NEW_ORDER,
}

export const inquiryRouteDetails = {
  buttonText: "New Inquiry",
  headerTitle: "Dashboard",
  navigateTo: CREATE_NEW_INQUIRY,
}

export const REPORT = 'report';
export const PRODUCT_WISE_INQUIRY = 'Product Wise Inquiry';
export const REASON_WISE_INQUIRY = 'Reason Wise Inquiry';
export const SOURCE_WISE_INQUIRY = 'Source Wise Inquiry';

export const INWORD_CHALLAN = 'Inword Challan';
export const OUTWORD_CHALLAN = 'Outword Challan';
export const STORE_VOUCHER = "Store Voucher";

export const IGNORED_ID = "ignored";

export const INTERNAL_PRODUCTION = "Produced in house";
