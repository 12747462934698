import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ASSIGN_GOODS } from "../../Helpers/ConstantProperties";

function AssignGoodsMenu({ item }) {
    const navigate = useNavigate();

    const onMenuClick = () => {
        navigate(ASSIGN_GOODS, { state: { item } });
    };

    return (
        <MenuItem onClick={onMenuClick}>
            Assign Goods
        </MenuItem>
    );
};

export default AssignGoodsMenu;