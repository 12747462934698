import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { commonFontSize, commonFontWeight } from "../../../Helpers/ConstantProperties";
import { ShowNumber } from "../../../Helpers/helpers";
import TaxCell from "./HSNTaxCell";

export default function HSNTotalRow({ cData }) {
    return (
        <Box display={{ xs: "none", md: "block" }}>
            <Grid2 container>

                <Grid2 xs={12} md={4} borderRight={{ xs: 'none', md: '1px solid lightgray' }}>
                    <Typography
                        fontWeight={commonFontWeight}
                        textAlign={{ xs: "left", md: "center" }}>
                        Total
                    </Typography>
                </Grid2>

                <Grid2
                    xs={12}
                    md={2.5}
                    textAlign={"right"}
                    paddingRight={1}
                    fontSize={commonFontSize}
                    fontWeight={commonFontWeight}
                    borderRight={{ xs: 'none', md: '1px solid lightgray' }}>

                    {ShowNumber(cData.totalTaxableAmount, 2, true)}

                </Grid2>

                <TaxCell tax={cData.totalTax} isIGST={cData.igstApp} />

                <Grid2 fontSize={commonFontSize}
                    fontWeight={commonFontWeight}
                    xs={12} md={2.5}
                    textAlign={"right"}
                    paddingRight={1}>

                    {ShowNumber(cData.totalTax, 2, true)}
                </Grid2>
            </Grid2>
        </Box>
    )
}