import { useContext } from "react";
import { DocumentContext, PageContext } from "../../GenericComponents/Layout/DocumentFormat";
import Grid2 from "@mui/material/Unstable_Grid2";
import TextEllipsis from "../TextEllipsis";

export default function DetailsComponent() {
    const { context } = useContext(DocumentContext);
    const { page } = useContext(PageContext);

    const detailsToShow = context.detailsToShow;

    return (
        <Grid2 container>
            <Grid2 xs={12}>
                <TextEllipsis text={"Details"} />
            </Grid2>
            <Grid2 container>
                {
                    Object.entries(detailsToShow).map(([Key, Value]) => (
                        <Grid2 key={Key} xs={12}>
                            <TextEllipsis
                                text={<span><strong>{Key}</strong> {Value}</span>}
                                variant="body2"
                            />
                        </Grid2>
                    ))
                }

                <Grid2 xs={12}>
                    <TextEllipsis
                        text={
                            <span>
                                <strong>{"Page "}</strong>
                                {(page + 1) + " of " + context.cData.totalPages}
                            </span>
                        }
                        variant="body2"
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )

}