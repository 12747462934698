import { useContext } from "react";
import QuotationInfo from "../QuotationInfo";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";

export default function ToComponent() {
    const { context } = useContext(DocumentContext);
    const inquiry = context.inquiry;

    const title = "To"

    return (
        <QuotationInfo
            title={title}
            name={inquiry.customerName}
            city={inquiry.city}
            gstin={inquiry.gstin}
            pan={inquiry.panNumber}
            phoneNumber={inquiry.contactPhone}
            email={inquiry.contactEmail}
        />
    )
}