import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import TableHeader from "../CommonComp/TableHeader";
import { defaultFormatTaxHeading, ShowNumber } from "../../../Helpers/helpers";
import { commonFontSize, PRIMARY_COLOR } from "../../../Helpers/ConstantProperties";

export default function TaxTable() {
    const { context } = useContext(DocumentContext);
    const cData = context.cData;

    return (
        <Box display={{ xs: "none", md: "block" }}>
            <Grid2 container className='rounded' border={{ xs: 'none', md: '1px solid lightgray' }}>
                <TableHeader propList={defaultFormatTaxHeading} />

                {
                    cData.taxTypeWiseList.map((taxType, index) => (
                        <Grid2
                            xs={12}
                            key={index}
                            textAlign={"center"}
                            border={{ xs: 'none', md: '1px solid lightgray' }}>

                            <Grid2 container paddingY={1}>
                                <Grid2 xs={12} md>
                                    <Typography
                                        fontSize={commonFontSize} sx={{ color: PRIMARY_COLOR }}>
                                        {taxType.type}
                                    </Typography>
                                </Grid2>

                                <Grid2 xs={12} md>
                                    <Typography
                                        fontSize={commonFontSize}>
                                        {ShowNumber(taxType.taxable, 2, true)}
                                    </Typography>
                                </Grid2>

                                <Grid2 xs={12} md>
                                    <Typography
                                        fontSize={commonFontSize}>
                                        {ShowNumber(taxType.tax, 2, true)}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    ))
                }

            </Grid2>
        </Box>
    )
}