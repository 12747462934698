import { Box, IconButton, Tooltip } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { CREATEPRODUCT, NOT_AVAILABLE, PRODUCT_TYPE_FINISHED, REQUIREMENT } from '../../Helpers/ConstantProperties';
import { useNavigate } from 'react-router-dom';

export const RequirementActionForm = ({ item, data, products }) => {
    const navigate = useNavigate();
    
    const index = products.findIndex(product => product.product.id === item.product.id);

    const navigateTo = () => navigate(CREATEPRODUCT, { state: { ...item.product, navigateTo: REQUIREMENT, extraProp: { inquiry: { ...data, products }, index: index } } })

    return (
        <Box className="d-flex flex-row w-100 justify-content-sm-center justify-content-lg-center">
            {item.product.type === PRODUCT_TYPE_FINISHED ? NOT_AVAILABLE : (
                <IconButton size="small" onClick={navigateTo}>
                    <Tooltip title={"Add Requirement"}>
                        <AddCircleRoundedIcon color="success" />
                    </Tooltip>
                </IconButton>
            )}
        </Box>
    )
}