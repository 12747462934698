import React from 'react';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CREATEPARTY } from '../../../Helpers/ConstantProperties';
import { PARTY_CUSTOMER_TYPE } from '../../../Helpers/ExtraProperties';

const CreateCustomer = ({ item }) => {
    const navigate = useNavigate();
    const isCustomerConnected = !!item.customerId;

    const onMenuClick = () => {
        const customerData = {
            name: item.customerName,
            email: item.contactEmail,
            phoneNumber: item.contactPhone,
            contactPerson: item.contactPerson,
            city: item.city,
            type: PARTY_CUSTOMER_TYPE
        };

        if(item.wa_id) customerData.wa_id = item.wa_id;

        navigate(CREATEPARTY, {
            state: { customerData: customerData, inquiryId: item.id }
        });
    };

    return (
        <MenuItem onClick={onMenuClick} disabled={isCustomerConnected}>
            Create Customer
        </MenuItem>
    );
};

export default CreateCustomer;