import { useContext } from "react"
import { AuthContext } from "../Auth/Auth"
import { FirmContext } from "../Contexts/FirmContext"
import { serviceHelpers } from "../../Helpers/ServiceHelpers"
import { useQuery } from "@tanstack/react-query"
import { BALANCE, COMMON_BATCH, INQUIRY_STORE_ACCOUNT_ID, STOCK, UPDATE_ON_INQUIRY, UPDATE_ON_VOUCHER } from "../../Helpers/ConstantProperties"
import { GenericErrorComponent } from "../GenericComponents/FormComponent/GenericAlertComponent"
import GenericSpinner from "../GenericComponents/FormComponent/GenericSpinner"
import LeadInStore from "./LeadInStore"
import { UserActionContext } from "../Dashboard/SummaryPage"
import { useLocation } from "react-router-dom"

export const StoreBalanceStock = (
    {
        Dashboardtitle = "Dilevery And Invoice Management",
        queryKeyValue = STOCK,
        BalanceAcc = INQUIRY_STORE_ACCOUNT_ID
    }
) => {
    const { token, currentUserObject } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const location = useLocation()

    const queryKey = [
        currentUserObject.uid,
        khID,
        UPDATE_ON_INQUIRY,
        UPDATE_ON_VOUCHER,
        queryKeyValue,
        BALANCE
    ]

    const { data, error, isLoading } = useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            const balance = await serviceHelpers.getProductBalanceOfAccount(token, khID, BalanceAcc);

            const grouped = balance.reduce((acc, entry) => {
                for (const [inquiryId, quantity] of Object.entries(entry.batches)) {

                    if (inquiryId === COMMON_BATCH) continue;
                    if (quantity === 0) continue;

                    if (!acc[inquiryId]) {
                        acc[inquiryId] = [];
                    }
                    acc[inquiryId].push({
                        resourceId: entry.resourceID,
                        inquiryId,
                        quantity,
                        resourceBalance: entry
                    });
                }
                return acc;
            }, {});

            return grouped;

        }
    });



    if (error) {
        return <GenericErrorComponent error={error} />;
    }

    if (isLoading) {
        return <GenericSpinner />;
    }
    
    return <>
        <UserActionContext.Provider value={{ routeDetails: { navigateTo: location.pathname } }}>
            <LeadInStore leads={data} Dashboardtitle={Dashboardtitle} />
        </UserActionContext.Provider>
    </>
}