import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";
import { BOMTableValues } from "../../../Helpers/helpers";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import TableHeader from "../CommonComp/TableHeader";
import BOMItemDetails from "./BOMItemDetails";
import BOMItemsTotal from "./BOMItemsTotal";

export default function BOMItems() {
    const { context } = useContext(DocumentContext);
    const cData = context.cData;

    const propList = BOMTableValues;

    return (
        <Box
            className='rounded'
            border={{ xs: 'none', md: '1px solid lightgray' }}
        >
            <Grid2 container>
                <TableHeader propList={propList} />

                {
                    cData?.items.map((item) => (
                        <Grid2
                            key={item.id}
                            xs={12}
                            border={{ xs: 'none', md: '1px solid lightgray' }}>

                            <Grid2 container paddingY={0.5}>
                                <BOMItemDetails item={item} />
                            </Grid2>

                        </Grid2>
                    ))
                }

                <Grid2 xs={12} className="bg-light">
                    <BOMItemsTotal />
                </Grid2>
            </Grid2>
        </Box>
    )
}