import { deepCopyObject } from "../../../Helpers/helpers";
import { fomatComponents } from "../CommonComp/commonFormatData";
import NewItemFormat from "./NewItemFormat";
import TaxHSNTable from "./TaxHSNTable";

const formatLayOutData = [
    {
        lable: "Logo",
        layout: {
            xs: 3,
        },
    },
    {
        lable: "Empty",
        layout: {
            display: { xs: "none", md: "block" },
            md: 4
        },
    },
    {
        lable: "Heading",
        layout: {
            xs: 8,
            md: 5
        },
    },
    {
        lable: "Divider",
        layout: {
            paddingY: 1,
            display: { xs: "none", md: "block" },
            md: 12
        },
    },
    {
        lable: "From",
        layout: {
            xs: 12,
            md: 4
        },
    },
    {
        lable: "To",
        layout: {
            xs: 12,
            md: 4
        },
    },
    {
        lable: "Details",
        layout: {
            xs: 12,
            md: 4
        },
    },
    {
        lable: "Items",
        layout: {
            paddingY: 2,
            xs: 12
        },
    },
    {
        showOnLast: true,
        lable: "Tax",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 12
        },
    },
    {
        showOnLast: true,
        lable: "Bank",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 6
        },
    },
    {
        showOnLast: true,
        lable: "Total",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 6
        },
    },
    {
        showOnLast: true,
        lable: "Terms",
        layout: {
            paddingY: 2,
            xs: 12,
            md: 9
        },
    },
    {
        showOnLast: true,
        lable: "Sign",
        layout: {
            paddingY: 2,
            display: { xs: "none", md: "block" },
            md: 3
        },
    }
]

const defaultGetComponent = (lable) => {
    let componentToReturn = null;

    switch (lable) {
        case "Items":
            componentToReturn = <NewItemFormat />
            break;

        case "Tax":
            componentToReturn = <TaxHSNTable />
            break;

        default:
            componentToReturn = fomatComponents(lable)
            break;
    }

    return componentToReturn;
}

// For BOM we need to change the item table
const bomLayout = deepCopyObject(formatLayOutData);
const bomIndex = bomLayout.findIndex((entry) => entry.lable === "Items");
if (bomIndex !== -1) bomLayout[bomIndex].lable = "BOMItems"

export const newFormat = {
    maxInPage: 20,
    pageSize: 30,
    // mainClass: "a4",
    quoteLayout: formatLayOutData,
    invoiceLayout: formatLayOutData,
    bomLayout: bomLayout,
    getComponent: defaultGetComponent,
}