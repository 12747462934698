import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { DocumentContext } from "../../GenericComponents/Layout/DocumentFormat";

export default function DocumentHeading() {
    const { context } = useContext(DocumentContext);

    return (
        <Box textAlign={"right"}>
            <Typography sx={{ mx: 1, color: 'primary.main' }} variant='h6' paddingX={3}>
                {context.documentTitle}
            </Typography>
        </Box>
    )
}