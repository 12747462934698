import React, { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { DecodeServerError } from '../../../Helpers/helpers';
import { GENERATE_INVOICE, INVOICE_SERIES_PREFIX, UPDATE_ON_INQUIRY } from '../../../Helpers/ConstantProperties';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import { useSnackbar } from '../../Contexts/SnackbarProvider';
import { FirmContext } from '../../Contexts/FirmContext';
import { AuthContext } from '../../Auth/Auth';

const GenerateInvoice = ({ item }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { showSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const queryFunction = async () => {
        const invoiceId = await serviceHelpers.getSeriesNumber(token, khID, { prefix: INVOICE_SERIES_PREFIX });

        const comment = `Invoice generated with ID: ${invoiceId.id}`;
        const data = await serviceHelpers.updateLeadStatus(
            token,
            khID,
            { invoiceId: invoiceId.id, invoiceDate : new Date().valueOf() },
            item.id,
            comment

        );
        return { id: data.id, comment, invoiceId: invoiceId.id };
    };

    const { mutate } = useMutation({
        mutationFn: queryFunction,
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.includes(UPDATE_ON_INQUIRY),
            });
            showSnackbar(data.comment, 'success');
            navigate(GENERATE_INVOICE, {
                state: {
                    item: { ...item, invoiceId: data.invoiceId },
                },
            });
        },
        onError: (error) => {
            const message = DecodeServerError(error);
            showSnackbar(message, 'error');
        },
    });

    return <MenuItem onClick={() => mutate()}>Generate Invoice</MenuItem>;
};

export default GenerateInvoice;