import { Box, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { createContext, useContext, useRef } from "react";
import PrintButton from "../Save/SaveButton";
import { DOC_BOM, DOC_QUOTE } from "../../../Helpers/ConstantProperties";
import { DocumentFormatContext } from "../../Contexts/DocumentFormatContext";

export const DocumentContext = createContext({ context: null });
export const PageContext = createContext({});

function createArray(count) {
    return Array.from({ length: count }, (v, k) => k);
}

export default function DocumentFormat({ context }) {
    const componentRef = useRef(null);
    const { selectedFormat } = useContext(DocumentFormatContext);

    const getComponent = selectedFormat.getComponent;
    let layout = selectedFormat.invoiceLayout

    if (context.document === DOC_QUOTE) layout = selectedFormat.quoteLayout;
    if (context.document === DOC_BOM) layout = selectedFormat.bomLayout;

    const pages = createArray(context.cData?.totalPages);

    return (
        <DocumentContext.Provider value={{ context: context }}>
            <Box sx={{ m: 2 }}>
                {
                    pages.map((page) => {
                        const isLast = (pages.length - 1) === page;

                        return (
                            <PageContext.Provider key={page} value={{ page: page }}>
                                <Paper
                                    sx={{ marginY: 2 }}
                                    className={selectedFormat.mainClass || ""}
                                    ref={componentRef}
                                    elevation={2}>

                                    <Grid2 container>
                                        {
                                            layout.map((part, index) => {

                                                if (part.showOnLast && !isLast) return <></>

                                                const defaultComponent = (
                                                    <Typography variant="h6" textAlign={"center"} color={"GrayText"}>
                                                        {part.lable}
                                                    </Typography>
                                                )

                                                const component = getComponent(part.lable)

                                                return (
                                                    <Grid2 key={index} {...part.layout}>
                                                        {component ? component : defaultComponent}
                                                    </Grid2>
                                                )
                                            }
                                            )}
                                    </Grid2>
                                </Paper>
                            </PageContext.Provider>
                        )
                    })
                }

                <Box className="w-100 py-2" justifyItems={"center"}>
                    <PrintButton componentRef={componentRef} />
                </Box>

            </Box>
        </DocumentContext.Provider>
    )
}