import { useContext } from "react";
import { FirmContext } from "../../Contexts/FirmContext";
import GenericLogo from "../../GenericComponents/Logo/GenericLogo";
import { Box } from "@mui/material";

export default function FormatLogo() {
    const { currentFirm } = useContext(FirmContext);
    return (
        <Box justifyContent={"start"}>
            <GenericLogo src={currentFirm.logoUrl} />
        </Box>
    )
}