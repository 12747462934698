import { useContext } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { INQUIRY_STORE_ACCOUNT_ID, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import AssignGoodsTableComponent from "./AssignGoodsTable";
import { useSnackbar } from "../Contexts/SnackbarProvider";
import { AssignGoodsHeading, DecodeServerError, filterAndSortBatchObject, calculateGoodTotalsForAssignment } from "../../Helpers/helpers";
import InquiryProductDetails from "../Inquiry/InquiryProductDetails";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";

function AssignGoods() {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { showSnackbar } = useSnackbar();

    const location = useLocation();
    const item = location.state.item;


    const queryFunction = async (obj) => {
        
         await Promise.all(
            Object.entries(obj).map(([productId, values]) => {
                return Promise.all(
                    Object.entries(values).map(async ([batchID, units]) => {
                        return await serviceHelpers.patchBalance(token, khID, {
                            accountID: INQUIRY_STORE_ACCOUNT_ID,
                            resourceID: productId,
                            units: units,
                            fromBatch: batchID,
                            toBatch: item.id,
                            notes: `${batchID} has been transferred`,
                        });
                    })
                );
            })
        );
    };


    const successMessage = () => `productAssign Successfuly`;

    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: queryFunction,

        onSuccess: async (data) => {
            await queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.includes(UPDATE_ON_INQUIRY),
            })
            showSnackbar(successMessage(data), 'success')
        },
        onError: (error) => {
            const message = DecodeServerError(error);
            showSnackbar(message, 'error');
        },
    });

    const handleSubmit = (product, balance) => {
        const batches = filterAndSortBatchObject(balance[0]?.batches);

        mutate({ [product.product.id]: calculateGoodTotalsForAssignment(batches, product.units) });
    }

    return <InquiryProductDetails
        headingList={AssignGoodsHeading}
        ResponsiveContentComponent={
            (props) => <AssignGoodsTableComponent
                {...props}
                handleSubmit={handleSubmit}
            />
        }
        data={item}
    />

}

export default AssignGoods;