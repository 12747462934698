import { useContext } from "react";
import { FirmContext } from "../../Contexts/FirmContext";
import QuotationInfo from "../QuotationInfo";

export default function FromComponent() {
    const { currentFirm } = useContext(FirmContext);
    const title = "From"

    return (
        <QuotationInfo
            title={title}
            name={currentFirm.name}
            address={currentFirm.address}
            city={currentFirm.city}
            pincode={currentFirm.pincode}
            gstin={currentFirm.gstin}
            pan={currentFirm.khID}
            phoneNumber={currentFirm.phoneNumber}
            email={currentFirm.email}
        />
    )
}